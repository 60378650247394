import * as React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { Select } from '.';


interface Config {
  type: 'const' | 'input' | 'number' | 'textarea' | 'boolean' | 'select' | 'date';
  label: string;
  name: string;
  selectList?: string[];
  data?: Values;
  required?: boolean;
}

interface Props {
  data?: Values;
  config: Config[];
  close: () => void;
  save: (data: any) => void;
  title: string;
}

interface State {
  values: Values;
}

interface Values {
  [x: string]: string | boolean;
}

interface Input extends Config {
  value?: string | boolean;
  values?: Values;
  onChange?: (newValue: string | boolean, name: string) => void;
}

const Checkbox = styled.input`
width: 320px;
margin-left: 30px;
`;

const MonospacedTextarea = styled.textarea`
font-family: "Lucida Console", "Menlo", "Monaco", "Courier", monospace;
`;

const Field = ({
  type,
  value,
  values,
  name,
  label,
  onChange,
  data,
  selectList,
  required
}: Input) => {
  const rawValue = values ? values[name] !== undefined ? values[name] : (data && data[name] !== null && data[name] !== undefined ?
    data[name] : '') : (value !== undefined && value !== null) ? value : '';

  const stringValue: string = rawValue && rawValue.toString ? rawValue.toString() : rawValue as any;

  switch (type) {
    case 'const':
      return (
        <div className="input-block">
          <label className="input-label">{label}</label>
          <input className="input-form" value={stringValue} type="text" disabled={true} />
        </div>
      );
    case 'input':
      return (
        <div className="input-block">
          <label className="input-label">{label}</label>
          <input className="input-form" value={stringValue} type="text" required={required ? required : false} onChange={(e) => onChange(e.target.value, name)} />
        </div>
      );
    case 'textarea':
      return (
        <div className="input-block">
          <label className="input-label">{label}</label>
          <MonospacedTextarea className="input-form" value={stringValue} required={required ? required : false} onChange={(e) => onChange(e.target.value, name)} />
        </div>
      );
    case 'number':
      return (
        <div className="input-block">
          <label className="input-label">{label}</label>
          <input className="input-form" value={stringValue} type="number" required={required ? required : false} onChange={(e) => onChange(e.target.value, name)} />
        </div>
      );
    case 'boolean':
      return (
        <div className="input-block">
          <label className="input-label">{label}</label>
          <Checkbox checked={Boolean(stringValue)} type="checkbox" required={required ? required : false} onChange={(e) => onChange(e.target.checked, name)} />
        </div>
      );
    case 'select':
      return (
        <div className="input-block">
          <label className="input-label">{label}</label>
          <Select list={selectList || []} initValue={data ? data[name] as any : undefined} dataUpdate={(result) => onChange(result, name)} />
        </div>
      );
    case 'date':
      return (
        <div className="input-block">
          <label className="input-label">{label}</label>
          <input className="input-date-form" type="date" min="2019-01-01" required={required ? required : false} onChange={(e) => onChange(e.target.value, name)} />
        </div>
      );
    default:
      return null;
  }
};

class Component extends React.PureComponent<Props, State> {
  state: State = {
    values: {},
  };

  updateValue = (newValue: string | boolean, name: string) => this.setState({
    ...this.state,
    values: {
      ...this.state.values,
      [name]: newValue,
    },
  })
  render() {
    return createPortal(<>
      <section className="page-modal active">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">{this.props.title}</h3>
            <a className="modal-close" onClick={this.props.close} />
          </div>
          <form onSubmit={async (e) => {
            e.preventDefault();
            await this.props.save(this.state.values);
            this.props.close();
          }} >
            <div className="modal-form">
              {
                this.props.config.map((config) => (
                  <Field data={this.props.data} key={config.name} {...config} values={this.state.values} onChange={this.updateValue} />
                ))
              }
            </div>
            <input className="btn modal-btn" value="Сохранить" type="submit" />
          </form>
        </div>
      </section>
    </>, document.getElementById('modals'));
  }
}

export default Component;
