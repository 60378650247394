import React from 'react';

import { TransportConfigs } from './transport/component';

import { Wrapper, TitleWrapper, Title} from './style';

interface IProps {

}

export const TransportConfPage = ({ }: IProps) => {
  return (
    <Wrapper>
      <TitleWrapper>
      <Title>
        Transport Config
      </Title>
      </TitleWrapper>
      <TransportConfigs/>
    </Wrapper>
  );
};
