import * as React from 'react';
import "react-table/react-table.css";

import PageLoader from '../page.loader';
import {
  LoadPageConfig,
  LoadFilter,
  LoadMenu,
  EditMenu,
  EditGroup,
  CreateProfile,
  DeleteMenu,
  CreateMenu,
  DeleteMenuGroup,
  CreateMenuGroup,
} from '../../actions/page';

import { Edit, Delete, Create } from '../buttons';
import { Modal, Filter, Confirmation } from '..';
import { Props, State } from './types';
import { getColumns } from './columns';
import { PageReactTable, GroupTitle } from './style';


class Component extends React.PureComponent<Props, State> {

  state: State = {
    client: '',
    menuType: '',
    openModal: null,
    openConfirm: null,
    expanded: {},
  };

  columns = getColumns((row) => (
    <>
      <Create onClick={() => {
        console.log('create');
        this.setState({
          openModal: 'createMenu',
          modalData: {
            parentId: row.original.menuId,
            client: row.original.client,
            menuType: row.original.menuType,
            parameter: row.original.parameter,
            enable: true,
          },
        });
      }} />
      <Edit onClick={() => {
        console.log(row);
        console.log('edit');
        this.setState({
          openModal: 'menu',
          modalData: row.original,
        });
      }} />
      <Delete onClick={() => {
        this.setState({
          openConfirm: () => {
            this.props.dispatch(DeleteMenu(this.state.client, this.state.menuType, row.original.menuId));
            console.log('delete');
          }
        });
      }}
      />
    </>
  ),
    (row) => (
      <>
        <Edit onClick={() => {
          console.log(row);
          console.log('edit');
          this.setState({
            openModal: 'group',
            modalData: row.original,
          });
        }} />
        <Delete onClick={() => {
          this.setState({
            openConfirm: () => {
              this.props.dispatch(DeleteMenuGroup(this.state.client, this.state.menuType, row.original.menuId, row.original.groupId));
              console.log('delete');
            }
          });
        }} />
      </>)
  );


  componentDidMount() {
    this.props.dispatch(LoadPageConfig(this.props.page.url));
  }

  componentDidUpdate() {
    if (this.props.pageConfig.isLoad && !this.props.pageConfig.filters.client) {
      this.props.dispatch(LoadFilter('client', this.props.page.url + '/filter/' + this.props.pageConfig.config.filters.client.url));
    }
  }


  treeTable = (props: { submenu: any }) => {
    const isSubmenu = props.submenu.length > 0 && props.submenu.some((item: any) => item.submenu);

    return (
      <div style={{ padding: "20px", paddingRight: "7px", paddingLeft: "7px" }}>
        <h3>Submenu:</h3>
        <br />
        <PageReactTable
          data={props.submenu}
          columns={this.columns.subColumns}
          defaultPageSize={props.submenu.length}
          showPagination={false}
          defaultSorted={[
            {
              id: "sort",
              desc: false,
            }
          ]}
          SubComponent={isSubmenu ? (row) => {

            if (row.original.submenu) {
              return (<this.treeTable submenu={row.original.submenu} />);
            }
            return null;
          } : undefined}
        />
      </div>
    );
  }

  render() {
    return (
      <>
        {this.state.openConfirm ?
          <Confirmation text="Вы собираетесь удалить запись" ok={this.state.openConfirm} close={() => this.setState({ ...this.state, openConfirm: null })} />
          : null}
        {this.state.openModal ?
          <Modal
            config={this.state.openModal === 'menu' || this.state.openModal === 'createMenu' ?
              this.props.pageConfig.config.menuFields || [] :
              this.state.openModal === 'group' || this.state.openModal === 'createGroup' ?
                this.props.pageConfig.config.groupFields || [] :
                this.state.openModal === 'create' ?
                  this.props.pageConfig.config.createFields || [] :
                  []
            }
            data={this.state.modalData}

            close={() => this.setState({
              openModal: null,
            })}

            title={this.state.openModal === 'menu' ? 'Редактирование' : this.state.openModal === 'group' ?
              'Редактирование группы' : this.state.openModal === 'create' ? 'Создание элемента неизвестной вложенности' :
                this.state.openModal === 'createMenu' ? 'Создание элемента первой вложенности' : 'Создать'}

            save={(data) => {

              const menu = { ...this.state.modalData, ...data };
              console.log('data');
              console.log(data);
              console.log('full data');
              console.log(menu);
              console.log('this.state.modalData');
              console.log(this.state.modalData);
              if (this.state.openModal === 'menu') {
                this.props.dispatch(EditMenu(this.state.client, this.state.menuType, menu.menuId, menu));
              }
              if (this.state.openModal === 'group') {
                this.props.dispatch(EditGroup(this.state.client, this.state.menuType, menu.groupId, menu));
              }
              if (this.state.openModal === 'create') {
                const initData = {};
                this.props.pageConfig.config.createFields.map((x: any) => x.type === 'select' ? initData[x.name] = x.selectList[0] : null);
                const result = { ...initData, ...data };
                this.props.dispatch(CreateProfile(result));
              }
              if (this.state.openModal === 'createMenu') {
                this.props.dispatch(CreateMenu(menu, this.state.client, this.state.menuType));
              }
              if (this.state.openModal === 'createGroup') {
                this.props.dispatch(CreateMenuGroup(menu, this.state.client, this.state.menuType));
              }
            }}
          /> : null}
        <div className="main-header">
          <h1 className="main-title">{this.props.page.title}</h1>
          <p className="main-description">{this.props.page.description}</p>
        </div>
        <div className="main-content">
          <a className="btn content-btn" onClick={() => this.setState({ openModal: 'create' })}>Создать</a>
          <a className="btn content-btn" style={{ width: 'auto' }} onClick={() => this.setState({
            openModal: 'createMenu',
            modalData: {
              parentId: null,
              client: this.state.client,
              menuType: this.state.menuType,
              enable: true,
            },
          })}>Создать пункт меню</a>
          <Filter initValue={this.props.pageConfig.defaultClient} name="Фильтрация по клиенту" list={this.props.pageConfig.filters.client || []}
            dataUpdate={(client: string) => {
              this.setState({ ...this.state, client });
              this.props.dispatch(LoadFilter(
                'menuType',
                this.props.page.url + '/filter/' + this.props.pageConfig.config.filters.menuType.url,
                client,
              ));
            }}
          />
          <Filter parentKey={this.state.client} name="Фильтрация по типу меню " list={this.props.pageConfig.filters.menuType || []}
            dataUpdate={(menuType: string) => {
              this.setState({ ...this.state, menuType });
              this.props.dispatch(LoadMenu(this.state.client, menuType));
            }}
          />
          {!this.props.pageConfig.loadData ?
            <PageLoader /> :
            <div className="table-wrapper">
              <PageReactTable
                data={this.props.pageConfig.menu || []}
                columns={this.columns.columns}
                defaultSorted={[
                  {
                    id: "sort",
                    desc: false,
                  }
                ]}
                expanded={this.state.expanded}
                onExpandedChange={(expanded) => this.setState({ ...this.state, expanded })}
                SubComponent={row => {
                  return (
                    <>
                      {row.original.submenu ?
                        <this.treeTable submenu={row.original.submenu} />
                        : null
                      }
                      <div style={{ padding: "20px", paddingRight: "7px", paddingLeft: "7px" }}>
                        <GroupTitle>
                          {<Create onClick={() => {
                            console.log(row.original);
                            this.setState({
                              openModal: 'createGroup',
                              modalData: {
                                menuId: row.original.menuId,
                                enable: true,
                              },
                            });
                          }} />}
                          <h3>Groups:</h3>
                        </GroupTitle>
                        {row.original.group ?
                          <PageReactTable
                            data={row.original.group}
                            columns={this.columns.columnsGroup}
                            defaultPageSize={row.original.group.length}
                            showPagination={false}
                          />
                          : null
                        }
                      </div>
                    </>
                  );
                }}
              />
            </div>
          }
        </div>
      </>
    );
  }
}

export default Component;
