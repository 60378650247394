import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Edit = styled.button`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0;
  margin-left: 10px;
  padding: 0;
  background-color: transparent;
  background-image: url(../img/edit.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 16px;
  cursor: pointer;
  :hover {
    filter: brightness(50%);
  }
`;

export const Delete = styled.button`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0;
  margin-left: 10px;
  padding: 0;
  background-color: transparent;
  background-image: url(../img/trash.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 15px;
  cursor: pointer;
  :hover {
    filter: brightness(50%);
  }
`;
