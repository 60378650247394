import styled from 'styled-components';
import ReactTable from 'react-table';
import selectTable from 'react-table/lib/hoc/selectTable';

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const PageReactTable = styled(ReactTable)`
  font-family: FiraSans, sans-serif;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 16px;
  color: #000000;
  text-align: center;
  word-break: break-word;
  .rt-tbody .rt-thead {
    background-color: #365d6d;
    color: white;
  }
`;

const SelectableReactTable = styled(selectTable(ReactTable))`
  font-family: FiraSans, sans-serif;
  font-size: 14px;
  text-align: left;
  word-break: break-word;
  .rt-tbody .rt-thead {
    background-color: #365d6d;
    color: white;
  }
  .rt-th {
    text-align: left;
  }
`;

const GroupTitle = styled.div`
  align-items: center;
  display: flex;
  button {
    margin-right: 10px;
  }
`;

export {
  Center,
  PageReactTable,
  SelectableReactTable,
  GroupTitle,
};
