import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import ReactTable from 'react-table';

import { State } from '../../../reducers';

import Confirmation from '../../confirmation';
import Notification from '../../notification';
import Modal from '../../modal';

import { Wrapper, Edit, Delete } from './style';

interface IProps {

}

interface IStats {
  id: number;
  client: string;
  bandName: string;
  band: number;
  ranType: number;
}

export const Band = ({ }: IProps) => {
  const activePage = useSelector((state: State) => state.page.activePage);
  const [bandList, setBandList] = useState<IStats[]>([]);
  const [loading, setLoading] = useState(false);
  const [removeId, setRemoveId] = useState<{ id: number, client: string } | null>(null);
  const [editId, setEditId] = useState<{ id: number, client: string } | null>(null);
  const [prevEditId, setPrevEditId] = useState<{ id: number, client: string } | null>(null);
  const [notification, setNotification] = useState<"yes" | "no" | null>(null);

  useEffect(()=> {
    if(editId){
      setPrevEditId(editId);
    }
  }, [editId]);

  const loadBand = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get('/node/wave/inventory/band');
      setLoading(false);
      if (!Array.isArray(response.data)) {
        return;
      }
      setBandList(response.data);
    } catch (e) {
      console.error(e);
    }
  }, [setLoading, setBandList]);

  useEffect(() => {
    if (activePage !== '/wave/inventory') {
      return;
    }
    loadBand()
  }, [activePage, loadBand]);

  const remove = useCallback(async () => {
    try {
      setRemoveId(null);

      const response = await axios.post(`/node/wave/inventory/band/remove`, removeId);
      if (response.data.status === 'ok') {
        loadBand();
        setNotification('yes');
      } else {
        setNotification('no');
      }

    } catch (e) {
      console.error(e);
      setNotification('no');
    }
  }, [removeId]);

  return (
    <Wrapper>
      {notification ? <Notification status={notification} close={() => setNotification(null)} /> : null}
      {editId ? <Modal
        title={'Редактировать'}
        config={[
          {
            type: 'const',
            label: 'Client',
            name: 'client',
          },
          {
            type: 'const',
            label: 'BandName',
            name: 'bandName',
          },
          {
            type: 'const',
            label: 'RanType',
            name: 'ranType',
          },
          {
            type: 'input',
            label: 'Band',
            name: 'band',
          },
        ]}
        data={bandList.find((x)=> x.id === editId.id) as any}
        close={() => setEditId(null)}
        save={async (value) => {
          try {
            const response = await axios.post(`/node/wave/inventory/band`, {...prevEditId, ...removeId, ...value});
            if (response.data.status === 'ok') {
              loadBand();
              setNotification('yes');
            } else {
              setNotification('no');
            }
      
          } catch (e) {
            console.error(e);
            setNotification('no');
          }
          setEditId(null)
        }}
      /> : null}
      {removeId ? <Confirmation
        text={`Удалить выбранный band`}
        close={() => setRemoveId(null)}
        ok={remove}
      /> : null}
      <ReactTable
        data={bandList}
        loading={loading}
        loadingText={'Загрузка...'}
        columns={[
          {
            Header: 'Клиент',
            accessor: 'client',
          },
          {
            Header: 'BandName',
            accessor: 'bandName',
          },
          {
            Header: 'band',
            accessor: 'band',
          },
          {
            Header: 'RanType',
            accessor: 'ranType',
          },
          {
            id: 'edit',
            Header: '',
            width: 100,
            sortable: false,
            accessor: ({ client, id }) => <Edit title="Редактировать" onClick={() => setEditId({ id, client })} />
          },
          {
            id: 'remove',
            Header: '',
            width: 100,
            sortable: false,
            accessor: ({ client, id }) => <Delete title="Удалить" onClick={() => setRemoveId({ id, client })} />
          },
        ]}
        pageSize={10}
      />
    </Wrapper>
  );
};
