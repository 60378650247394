import styled from 'styled-components';


const Nav = styled.nav`
  position: absolute;
  left: 0;
  top: 60px;
  bottom: 0;
  width: 100px;
  min-width: 100px;
  height: calc(100% - 60px);
  box-sizing: border-box;
  background-color: #466e92;
  border-right: 1px solid #979797;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    top: 45px;
    height: calc(100% - 45px);
    width: 40px;
    min-width: 40px;
  }
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Li = styled.li`
  width: 100%;
  height: auto;
`;

interface LinkProps {
  disabled: boolean;
  selected: boolean;
  image: string;
  positiony: string;
  small?: boolean;
}

const Link = styled.a<LinkProps>`
  display: block;
  width: 100%;
  height: auto;
  padding: ${(props) => props.small ? '50px 5px 10px' : '80px 5px 10px'};
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  font-family: HelveticaNeue, Arial;
  letter-spacing: 0.5px;
  color: #fff;
  text-align: center;
  text-overflow: ellipsis;
  background-position: 50% 20px;
  background-size: ${(props) => props.small ? '30px' : '60px'};
  background-repeat: no-repeat;
  overflow: hidden;

  cursor: ${(props) => props.disabled ? 'default' : 'pointer'};
  background-color: ${(props) => props.selected ? '#245467' : '#365d6d'};
  background-image: ${(props) => props.image};
  background-position-y: ${(props) => props.positiony};

  :hover {
    background-color: #477f96;
  }
  @media (max-width: 768px) {
    padding: 20px;
    font-size: 0;
    line-height: 0;
    background-size: 25px;
    background-position: 50% 50%;
  }
`;

const Exit = styled.li`
  width: 100%;
  height: auto;
  margin: auto;
  margin-bottom: 0;
`;

export {
  Li,
  Nav,
  Ul,
  Link,
  Exit
};