import hexToRgba from 'hex-to-rgba';

import { IOption } from '../select';

interface ICommonItem {
  id: number;
  titleKey: string;
  color?: string;
}

export const toOption = <T extends ICommonItem>(item: T, opacity = 1): IOption => ({
  value: item ? item.id : undefined,
  label: item ? item.titleKey : '',
  color: item && item.color ? hexToRgba(item.color, opacity) : null,
});

export const toOptionList = <T extends ICommonItem>(itemList: T[], opacity = 1) => itemList.map((item) => toOption(item, opacity));

export const toOptionListWithOpacity = <T extends ICommonItem>(opacity: number) => (itemList: T[]) => toOptionList(itemList, opacity);


