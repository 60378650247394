import * as React from 'react';
import { createPortal } from 'react-dom';
import { findDOMNode } from 'react-dom';

interface Props {
  text: string;
  close: () => void;
  ok: () => void;
}

interface State {
}

class Component extends React.PureComponent<Props, State> {

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (event: any) => {
    const domNode = findDOMNode(this);
    if(typeof domNode === 'string'){
      return;
    }
    if ((!domNode || !domNode.contains(event.target))) {
      this.props.close();
    }
  }

  render() {
    return createPortal(
      <section className="modal modal-custom active">
        <div className="modal-content">
          <p className="modal-text modal-text-custom">{this.props.text}</p>
          <div className="modal-btn-group">
            <input value="Да" onClick={() => {
              this.props.close();
              this.props.ok();
            }} className="btn modal-btn modal-yes" type="submit" />
            <input value="Отмена" onClick={this.props.close} className="btn modal-btn modal-no" type="submit" />
          </div>
        </div>
      </section>, document.getElementById('modals'));
  }
}

export default Component;
