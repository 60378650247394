import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Papa from 'papaparse';

import Select from '../../select';
import Loader from '../../page.loader';
import Notification from '../../notification';

import { State } from '../../../reducers';

import { Wrapper, Item, Label, Button, CustomItem, CustomWrapper, CustomSelect, CustomList, CustomListItem } from './style';

interface IProps {

}

interface IClientConfig {
  custom: string[];

  client?: string;
  mcc?: string;
  mnc?: string;
  lac?: string;
  cellId?: string;
  siteId?: string;
  lat?: string;
  lng?: string;
  technology?: string;

  height?: string;
  azimuth?: string;
  electricalTilt?: string;
  mechanicalTilt?: string;
  rnc?: string;
  band?: string;
  address?: string;
  siteType?: string;
  siteName?: string;

  regionName?: string;
  regionNum?: string;
  vBeamwifth?: string;
  hBeamwifth?: string;
  bandwidth?: string;
  cellName?: string;
  maxElDtilt?: string;
  maxMecDtilt?: string;
  ret?: string;
  bandsOnPlace?: string;
  cellLat?: string;
  cellLng?: string;
  antennaType?: string;
  place?: string;
  terrainType?: string;
  pciPsc?: string;
}

export const Import = ({ }: IProps) => {
  const activePage = useSelector((state: State) => state.page.activePage);
  const [loader, setLoader] = useState(false);
  const [clientList, setClientList] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [headerList, setHeaderList] = useState<string[]>([]);
  const [clientConfig, setClientConfig] = useState<IClientConfig>({ custom: [] });
  const [notification, setNotification] = useState<"yes" | "no" | null>(null);

  const [client, setClient] = useState('');
  const [mcc, setMcc] = useState('');
  const [mnc, setMnc] = useState('');
  const [lac, setLac] = useState('');
  const [cellId, setCellId] = useState('');
  const [siteId, setSiteId] = useState('');
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [technology, setTechnology] = useState('');

  const [height, setHeight] = useState('');
  const [azimuth, setAzimuth] = useState('');
  const [electricalTilt, setElectricalTilt] = useState('');
  const [mechanicalTilt, setMechanicalTilt] = useState('');
  const [rnc, setRnc] = useState('');
  const [band, setBand] = useState('');
  const [address, setAddress] = useState('');
  const [siteType, setSiteType] = useState('');
  const [siteName, setSiteName] = useState('');
  const [custom, setCustom] = useState<string[]>([]);
  const [customTemp, setCustomTemp] = useState('');

  const [regionName, setRegionName] = useState('');
  const [regionNum, setRegionNum] = useState('');
  const [vBeamwifth, setVBeamwifth] = useState('');
  const [hBeamwifth, setHBeamwifth] = useState('');
  const [bandwidth, setBandwidth] = useState('');
  const [cellName, setCellName] = useState('');
  const [maxElDtilt, setMaxElDtilt] = useState('');
  const [maxMecDtilt, setMaxMecDtilt] = useState('');
  const [ret, setRet] = useState('');
  const [bandsOnPlace, setBandsOnPlace] = useState('');
  const [cellLat, setCellLat] = useState('');
  const [cellLng, setCellLng] = useState('');
  const [antennaType, setAntennaType] = useState('');
  const [place, setPlace] = useState('');
  const [terrainType, setTerrainType] = useState('');
  const [pciPsc, setPciPsc] = useState('');


  const [buttonEnabled, setButtonEnabled] = useState(false);

  useEffect(() => {
    if (headerList.length !== 0 && client && mcc && mnc && lac && cellId && siteId && lat && lng && technology) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }

  }, [headerList, client, mcc, mnc, lac, cellId, siteId, lat, lng, technology]);

  const loadClientConfig = useCallback(async (client: string) => {
    try {
      const response = await axios.get(`/node/wave/inventory/config/${client}`);
      setClientConfig(response.data);

      response.data.mcc && setMcc(response.data.mcc);
      response.data.mnc && setMnc(response.data.mnc);
      response.data.lac && setLac(response.data.lac);
      response.data.cellId && setCellId(response.data.cellId);
      response.data.siteId && setSiteId(response.data.siteId);
      response.data.lat && setLat(response.data.lat);
      response.data.lng && setLng(response.data.lng);
      response.data.technology && setTechnology(response.data.technology);

      response.data.height && setHeight(response.data.height);
      response.data.azimuth && setAzimuth(response.data.azimuth);
      response.data.electricalTilt && setElectricalTilt(response.data.electricalTilt);
      response.data.mechanicalTilt && setMechanicalTilt(response.data.mechanicalTilt);
      response.data.rnc && setRnc(response.data.rnc);
      response.data.band && setBand(response.data.band);
      response.data.address && setAddress(response.data.address);
      response.data.siteType && setSiteType(response.data.siteType);
      response.data.siteName && setSiteName(response.data.siteName);
      response.data.custom && setCustom(response.data.custom);

      response.data.regionName && setRegionName(response.data.regionName);
      response.data.regionNum && setRegionNum(response.data.regionNum);
      response.data.vBeamwifth && setVBeamwifth(response.data.vBeamwifth);
      response.data.hBeamwifth && setHBeamwifth(response.data.hBeamwifth);
      response.data.bandwidth && setBandwidth(response.data.bandwidth);
      response.data.cellName && setCellName(response.data.cellName);
      response.data.maxElDtilt && setMaxElDtilt(response.data.maxElDtilt);
      response.data.maxMecDtilt && setMaxMecDtilt(response.data.maxMecDtilt);
      response.data.ret && setRet(response.data.ret);
      response.data.bandsOnPlace && setBandsOnPlace(response.data.bandsOnPlace);
      response.data.cellLat && setCellLat(response.data.cellLat);
      response.data.cellLng && setCellLng(response.data.cellLng);
      response.data.antennaType && setAntennaType(response.data.antennaType);
      response.data.place && setPlace(response.data.place);
      response.data.terrainType && setTerrainType(response.data.terrainType);
      response.data.pciPsc && setPciPsc(response.data.pciPsc);
    } catch (e) {
      console.error(e);
    }
  }, [setClientConfig]);

  useEffect(() => {
    if (!client) {
      return;
    }
    loadClientConfig(client);
  }, [client]);

  useEffect(() => {
    if (!selectedFile) {
      return;
    }
    const fileReader = new FileReader();

    fileReader.readAsText(selectedFile);

    fileReader.addEventListener('load', () => {
      Papa.parse(fileReader.result as string, {
        skipEmptyLines: true,
        header: true,
        //transformHeader: (header) => reversedMapping[header],
        step: (result: any, parser) => { // results is 'any' because of bug in typings file
          // empty line in file, skip it
          if (result.errors.length === 1 && result.errors[0].code === 'TooFewFields') {
            parser.resume();
            return;
          }

          if (result.errors.length > 0) {
            console.log(result.errors);
            parser.abort();
            return;
          }

          setHeaderList(Object.keys(result.data));
          parser.abort();
        },
      });


    })

  }, [selectedFile]);

  const loadClientList = useCallback(async () => {
    try {
      const response = await axios.get('/node/wave/inventory/config');
      if (!Array.isArray(response.data)) {
        return;
      }
      setClientList(response.data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (activePage !== '/wave/inventory') {
      return;
    }
    loadClientList()
  }, [activePage, loadClientList]);

  const onUpload = async () => {
    if (!buttonEnabled) {
      return;
    }
    try {
      setLoader(true);

      const response = await axios.post(`/node/wave/inventory/config/${client}`, {
        client,
        mcc,
        mnc,
        lac,
        cellId,
        siteId,
        lat,
        lng,
        technology,

        height: height || null,
        azimuth: azimuth || null,
        electricalTilt: electricalTilt || null,
        mechanicalTilt: mechanicalTilt || null,
        rnc: rnc || null,
        band: band || null,
        address: address || null,
        siteType: siteType || null,
        siteName: siteName || null,

        regionName: regionName || null,
        regionNum: regionNum || null,
        vBeamwifth: vBeamwifth || null,
        hBeamwifth: hBeamwifth || null,
        bandwidth: bandwidth || null,
        cellName: cellName || null,
        maxElDtilt: maxElDtilt || null,
        maxMecDtilt: maxMecDtilt || null,
        ret: ret || null,
        bandsOnPlace: bandsOnPlace || null,
        cellLat: cellLat || null,
        cellLng: cellLng || null,
        antennaType: antennaType || null,
        place: place || null,
        terrainType: terrainType || null,
        pciPsc: pciPsc || null,

        custom,
      });
      if (response.data.status !== 'ok') {
        setNotification('no');
        return;
      }


      const data = new FormData()
      data.append('file', selectedFile);


      const result = await axios.post(`/node/wave/inventory/upload/${client}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          console.log(percentCompleted)
        }
      });

      setLoader(false);
      if (result.data.status !== 'ok') {
        setNotification('no');
        return;
      }

      setNotification('yes');


    } catch (e) {
      setNotification('no');
      console.error(e);
    }
  };


  if (loader) {
    return (
      <Wrapper><Loader /></Wrapper>
    );
  }

  return (
    <Wrapper>
      {notification ? <Notification status={notification} close={() => setNotification(null)} /> : null}
      <Item error={!client}>
        <Label>Клиент</Label>
        <Select
          list={clientList}
          initValue={''}
          dataUpdate={setClient}
        />
      </Item>
      <Item error={!selectedFile}>
        <Label>CSV</Label>
        <input type="file" name="file" onChange={(v) => setSelectedFile(v.target.files[0])} />
      </Item>

      {headerList.length !== 0 && client ? (<>
        <Item error={!mcc}>
          <Label>MCC</Label>
          <Select
            list={headerList}
            initValue={clientConfig.mcc || ''}
            dataUpdate={setMcc}
          />
        </Item>
        <Item error={!mnc}>
          <Label>MNC</Label>
          <Select
            list={headerList}
            initValue={clientConfig.mnc || ''}
            dataUpdate={setMnc}
          />
        </Item>
        <Item error={!lac}>
          <Label>LAC</Label>
          <Select
            list={headerList}
            initValue={clientConfig.lac || ''}
            dataUpdate={setLac}
          />
        </Item>
        <Item error={!cellId}>
          <Label>Cell ID</Label>
          <Select
            list={headerList}
            initValue={clientConfig.cellId || ''}
            dataUpdate={setCellId}
          />
        </Item>
        <Item error={!siteId}>
          <Label>BS ID</Label>
          <Select
            list={headerList}
            initValue={clientConfig.siteId || ''}
            dataUpdate={setSiteId}
          />
        </Item>
        <Item error={!lat}>
          <Label>BS Latitude</Label>
          <Select
            list={headerList}
            initValue={clientConfig.lat || ''}
            dataUpdate={setLat}
          />
        </Item>
        <Item error={!lng}>
          <Label>BS Longitude</Label>
          <Select
            list={headerList}
            initValue={clientConfig.lng || ''}
            dataUpdate={setLng}
          />
        </Item>
        <Item error={!technology}>
          <Label>Technology</Label>
          <Select
            list={headerList}
            initValue={clientConfig.technology || ''}
            dataUpdate={setTechnology}
          />
        </Item>
        <Item>
          <Label>Height (m)</Label>
          <Select
            list={headerList}
            initValue={clientConfig.height || ''}
            dataUpdate={setHeight}
          />
        </Item>
        <Item>
          <Label>Azimuth</Label>
          <Select
            list={headerList}
            initValue={clientConfig.azimuth || ''}
            dataUpdate={setAzimuth}
          />
        </Item>
        <Item>
          <Label>Electrical Tilt</Label>
          <Select
            list={headerList}
            initValue={clientConfig.electricalTilt || ''}
            dataUpdate={setElectricalTilt}
          />
        </Item>
        <Item>
          <Label>Mechanical Tilt</Label>
          <Select
            list={headerList}
            initValue={clientConfig.mechanicalTilt || ''}
            dataUpdate={setMechanicalTilt}
          />
        </Item>
        <Item>
          <Label>RNC</Label>
          <Select
            list={headerList}
            initValue={clientConfig.rnc || ''}
            dataUpdate={setRnc}
          />
        </Item>
        <Item>
          <Label>BAND</Label>
          <Select
            list={headerList}
            initValue={clientConfig.band || ''}
            dataUpdate={setBand}
          />
        </Item>
        <Item>
          <Label>Address</Label>
          <Select
            list={headerList}
            initValue={clientConfig.address || ''}
            dataUpdate={setAddress}
          />
        </Item>
        <Item>
          <Label>LOCATION</Label>
          <Select
            list={headerList}
            initValue={clientConfig.siteType || ''}
            dataUpdate={setSiteType}
          />
        </Item>
        <Item>
          <Label>Site Name</Label>
          <Select
            list={headerList}
            initValue={clientConfig.siteName || ''}
            dataUpdate={setSiteName}
          />
        </Item>


        <Item>
          <Label>Регион</Label>
          <Select
            list={headerList}
            initValue={clientConfig.regionName || ''}
            dataUpdate={setRegionName}
          />
        </Item>
        <Item>
          <Label>Номер региона</Label>
          <Select
            list={headerList}
            initValue={clientConfig.regionNum || ''}
            dataUpdate={setRegionNum}
          />
        </Item>
        <Item>
          <Label>Вертикальный раскрыв антенны</Label>
          <Select
            list={headerList}
            initValue={clientConfig.vBeamwifth || ''}
            dataUpdate={setVBeamwifth}
          />
        </Item>
        <Item>
          <Label>Горизонтальный раскрыв антенны</Label>
          <Select
            list={headerList}
            initValue={clientConfig.hBeamwifth || ''}
            dataUpdate={setHBeamwifth}
          />
        </Item>
        <Item>
          <Label>Ширина полосы</Label>
          <Select
            list={headerList}
            initValue={clientConfig.bandwidth || ''}
            dataUpdate={setBandwidth}
          />
        </Item>
        <Item>
          <Label>Имя соты</Label>
          <Select
            list={headerList}
            initValue={clientConfig.cellName || ''}
            dataUpdate={setCellName}
          />
        </Item>
        <Item>
          <Label>Макс эл угол</Label>
          <Select
            list={headerList}
            initValue={clientConfig.maxElDtilt || ''}
            dataUpdate={setMaxElDtilt}
          />
        </Item>
        <Item>
          <Label>Макс мех угол</Label>
          <Select
            list={headerList}
            initValue={clientConfig.maxMecDtilt || ''}
            dataUpdate={setMaxMecDtilt}
          />
        </Item>
        <Item>
          <Label>Наличие РЕТ</Label>
          <Select
            list={headerList}
            initValue={clientConfig.ret || '' as any}
            dataUpdate={setRet}
          />
        </Item>
        <Item>
          <Label>Другие технологии на антенне</Label>
          <Select
            list={headerList}
            initValue={clientConfig.bandsOnPlace || ''}
            dataUpdate={setBandsOnPlace}
          />
        </Item>
        <Item>
          <Label>LAT антенны</Label>
          <Select
            list={headerList}
            initValue={clientConfig.cellLat || ''}
            dataUpdate={setCellLat}
          />
        </Item>
        <Item>
          <Label>LNG антенны</Label>
          <Select
            list={headerList}
            initValue={clientConfig.cellLng || ''}
            dataUpdate={setCellLng}
          />
        </Item>
        <Item>
          <Label>Тип антенны</Label>
          <Select
            list={headerList}
            initValue={clientConfig.antennaType || ''}
            dataUpdate={setAntennaType}
          />
        </Item>
        <Item>
          <Label>Название местности</Label>
          <Select
            list={headerList}
            initValue={clientConfig.place || ''}
            dataUpdate={setPlace}
          />
        </Item>
        <Item>
          <Label>Тип местности</Label>
          <Select
            list={headerList}
            initValue={clientConfig.terrainType || ''}
            dataUpdate={setTerrainType}
          />
        </Item>
        <Item>
          <Label>PCI/PSC</Label>
          <Select
            list={headerList}
            initValue={clientConfig.pciPsc || ''}
            dataUpdate={setPciPsc}
          />
        </Item>










        <CustomItem>
          <Label>Custom</Label>
          <CustomWrapper>

            <CustomSelect>

              <Select
                list={headerList}
                initValue={''}
                dataUpdate={setCustomTemp}
              />
              <button onClick={() => setCustom([...custom, customTemp])}>+</button>
            </CustomSelect>
            <CustomList>
              {custom.map((customItem) => <CustomListItem key={customItem}>
                <p>{customItem}</p>
                <button onClick={()=> setCustom(custom.filter((x) => x !== customItem))}>Delete</button>
              </CustomListItem>)}

            </CustomList>
          </CustomWrapper>

        </CustomItem>

      </>) : null}

      <Button disable={!buttonEnabled} onClick={onUpload}>Загрузить</Button>

    </Wrapper>
  );
};

