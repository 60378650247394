import * as React from 'react';

import { Select } from '.';

interface Props {
  name: string;
  list: string[];
  initValue?: string;
  parentKey?: string;
  dataUpdate: (value: string) => void;
}

const Component = (props: Props) => (<div className="select-block filter-block">
  <h4 className="filter-title">{props.name}</h4>
  <Select parentKey={props.parentKey} initValue={props.initValue} list={props.list} dataUpdate={props.dataUpdate} />
</div>);


export default Component;
