import { State } from '../reducers';
import { createSelector } from 'reselect';

/*
 * Get the todos state from the root state
 */
const getConfigState = ((state: State) => state.config);

/*
 * Getting todos array from todos State
 */
const getConfig = createSelector([getConfigState], (s) => s);

export default getConfig;
