import * as React from 'react';
import { Center } from './style';

const checkbox = (row: { value: boolean }) => {
  return (
    <Center>
      <p>{row.value ? 'Да' : 'Нет'}</p>
    </Center>
  );
};

const checkboxList = [{
  Header: 'Включен',
  accessor: 'enable',
  Cell: checkbox,
  maxWidth: 80,
},
{
  Header: 'Выбранный',
  accessor: 'checked',
  Cell: checkbox,
  maxWidth: 100,
},];

const groupCheckboxList = [{
  Header: 'Включен',
  accessor: 'enable',
  Cell: checkbox,
  maxWidth: 80,
},
{
  Header: 'Выбранный',
  accessor: 'selected',
  Cell: checkbox,
  maxWidth: 90,
},
];

export {
  checkboxList,
  groupCheckboxList,
};