import { connect } from 'react-redux';
import { Header } from '../components';
import { State } from '../reducers';
import { getUi } from '../selectors';

const mapStateToProps = (state: State) => ({
  activeHeader: getUi(state)
});

export default connect(mapStateToProps)(Header);
