const post = <Data>(url: string, data: any): Promise<Data> => fetch(`/${url}`, {
  method: 'post',
  credentials: 'include',
  headers: {
    'content-type': 'application/json; charset=utf-8',
  },
  body: JSON.stringify(data),
}).then(res => res.json());

const remove = <Data>(url: string): Promise<Data> => fetch(`/${url}`, {
  method: 'delete',
  credentials: 'include',
}).then(res => res.status === 204 ? { result: 'ok' } : res.json());

const get = <Data>(url: string, parseJson: boolean = true): Promise<Data> => fetch(`/${url}`, {
  credentials: 'include',
}).then(res => parseJson ? res.json() : true);


export {
  get,
  post,
  remove,
};