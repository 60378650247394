import { takeLatest } from 'redux-saga/effects';
import { ActionConfigTypes } from '../actions/config';
import { ActionPageTypes } from '../actions/page';
import { ActionAdminTypes } from '../actions/admin';
import { loadConfig } from './config';
import {
  loadPageConfig,
  loadFilter,
  loadMenu,
  editMenu,
  editGroup,
  createProfile,
  deleteMenu,
  createMenu,
  deleteMenuGroup,
  createMenuGroup,
} from './page';
import { logOut } from './admin';

function* rootSaga() {
  yield takeLatest(ActionConfigTypes.LOAD_CONFIG, loadConfig);
  yield takeLatest(ActionPageTypes.LOAD_PAGE_CONFIG, loadPageConfig);
  yield takeLatest(ActionPageTypes.LOAD_FILTER, loadFilter);
  yield takeLatest(ActionPageTypes.LOAD_MENU, loadMenu);
  yield takeLatest(ActionPageTypes.UPDATE_MENU, loadMenu);
  yield takeLatest(ActionPageTypes.EDIT_MENU, editMenu);
  yield takeLatest(ActionPageTypes.EDIT_GROUP, editGroup);
  yield takeLatest(ActionPageTypes.CREATE_PROFILE, createProfile);
  yield takeLatest(ActionPageTypes.DELETE_MENU, deleteMenu);
  yield takeLatest(ActionPageTypes.CREATE_MENU, createMenu);
  yield takeLatest(ActionPageTypes.deleteMenuGroup, deleteMenuGroup);
  yield takeLatest(ActionPageTypes.createMenuGroup, createMenuGroup);
  yield takeLatest(ActionAdminTypes.LOG_OUT, logOut);
}

export default rootSaga;
