import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { SelectableReactTable } from '../page/style';
import { Filter, Modal } from '../';
import Notification from '../notification';
import { Column, RowInfo, Filter as ReactTableFilter } from 'react-table';
import PageLoader from '../page.loader';
import { PageOptions } from '../../types/config';
import "react-table/react-table.css";

const getColumns = (options: PageOptions): Column[] => {
  return options.table.fields.filter((x: any) => !x.hidden).map((field: any) => {
    const column: Column = {
      Header: field.title,
      accessor: field.name,
      filterMethod: (filter: ReactTableFilter, row: any) => {
        return row[filter.id] ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase()) : false;
      }
    };
    if (field.style && field.style['max-width']) {
      column.maxWidth = parseInt(field.style['max-width'], 10);
    }
    return column;
  });
};

const modalConfig = [{
  type: 'date' as const,
  label: 'Действителен до',
  name: 'expire',
  required: false,
}];

export const SelectableTable = ({ options }: { options: PageOptions }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState<string[]>([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [data, setData] = useState<Array<{[key: string]: any}>>([]);
  const [selection, setSelection] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState<'yes' | 'no' | null>(null);
  const tableEl = useRef(null);
  const columns = getColumns(options);

  const getFilteredData = async (client: string, didCancel?: boolean) => {
    setIsLoading(true);
    try {
      const filteredData = await axios(`${options.filters[0].queries.get.url}/${client}`, {
        method: options.filters[0].queries.get.method,
      });

      if (!didCancel) {
        setData(filteredData.data);
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const allClients = await axios(options.filters[0].queries.list.url, {
          method: options.filters[0].queries.list.method,
        });
        setClients(allClients.data);
        setSelectedClient(allClients.data[0]);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    let didCancel = false;
    if (selectedClient) {
      getFilteredData(selectedClient, didCancel);
    }

    return () => {
      didCancel = true;
    };
  }, [selectedClient]);

  const isSelected = (id: string) => selection.includes(id);
  const toggleSelection = (key: string, shiftKey: boolean, row: any) => {
    let newSelection = [...selection];
    const keyIndex = newSelection.indexOf(row.id);
    if (keyIndex >= 0) {
      newSelection = [
        ...newSelection.slice(0, keyIndex),
        ...newSelection.slice(keyIndex + 1)
      ];
    } else {
      newSelection.push(row.id);
    }
    setSelection(newSelection);
  };
  const toggleAll = () => {
    const newSelectAll = !selectAll;
    let newSelection = [];
    if (newSelectAll) {
      const wrappedInstance = tableEl.current.getWrappedInstance();
      const currentRecords: any[] = wrappedInstance.getResolvedState().sortedData;
      newSelection = currentRecords.map((item) => item._original.id);
    }
    setSelectAll(newSelectAll);
    setSelection(newSelection);
  };

  const updateRecords = async (date: {[key: string]: string}) => {
    try {
      await axios(options.queries.update.url, {
        method: options.queries.update.method,
        data: {
          ...date,
          ids: selection,
        }
      });
      setIsModalOpen(false);
      setNotificationStatus('yes');
      setSelection([]);
      setSelectAll(false);
      getFilteredData(selectedClient);
    } catch (e) {
      setNotificationStatus('no');
      console.error(e);
    }
  };

  if (isLoading) { return (<PageLoader />); }

  return (
    <>
      {notificationStatus
        ? <Notification
            status={notificationStatus}
            close={() => setNotificationStatus(null)} />
        : null
      }
      {isModalOpen && selection.length
        ? <Modal
            title={`Обновить ${options.title.toLowerCase()}`}
            config={modalConfig}
            close={() => setIsModalOpen(false)}
            save={updateRecords}
          />
        : null
      }
      <div className='main-header'>
        <h1 className='main-title'>{options.title}</h1>
        <p className='main-description'>{options.description}</p>
      </div>
      <div className='main-content'>
        <button className="btn content-btn" disabled={!selection.length} onClick={() => setIsModalOpen(true)}>Изменить</button>
        <Filter
          name="Фильтрация по клиенту"
          initValue={selectedClient}
          list={clients}
          dataUpdate={setSelectedClient}
        />
        <div className="table-wrapper">
          <SelectableReactTable
            ref={tableEl}
            keyField="id"
            data={data}
            columns={columns}
            filterable={true}
            defaultSorted={[{ id: options.idField, desc: true }]}
            showPagination={true}
            selectAll={selectAll}
            isSelected={isSelected}
            toggleSelection={toggleSelection}
            toggleAll={toggleAll}
            getTrProps={(state: any, rowInfo: RowInfo) => {
              return {
                style: {
                  background: rowInfo && isSelected(rowInfo.original.id) ? '#e3f3ff' : 'inherit'
                }
              };
            }}
          />
        </div>
      </div>
    </>
  );
};
