import { ActionPageTypes, Action } from '../actions/page';
import { bridge } from '../shared';
import { PageConfig } from '../types/page';
import { Menu } from '../types';

const idList = new Map<string, string>();

bridge.getFunc('getOptions').then((f) => f()).then((x: any) => x.structure.forEach((structure: any) => {
  if (!structure.blocks) {
    return;
  }
  structure.blocks.forEach((block: any) => block.pages.forEach((page: any) => idList.set(`/${structure.name}/${page.name}`, `page-${idList.size}`)));

})).then(() => bridge.getFunc('openPage')).then((openPage) => {
  const [defaultUrl, defaultId] = idList.entries().next().value;
  const id = history.state ? idList.get(history.state.href) : undefined;
  const url = history.state ? history.state.href : undefined;
  openPage(id ? url : defaultUrl, 'user', id ? id : defaultId);
});

const getId = (name: string) => {
  return idList.get(name);
};

export interface State {
  isLoad: boolean;
  config?: PageConfig;
  filters: { [x: string]: string[] };
  menu?: Menu[];
  defaultClient?: string;
  loadData: boolean;
  activePage: string;
}

const initialState = {
  isLoad: false,
  filters: {},
  loadData: false,
  activePage: window.location.pathname,
};

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionPageTypes.SET_ACTIVE_PAGE: {
      bridge.getFunc('openPage').then((openPage) => {
        openPage(action.payload.url, action.payload.name, getId(action.payload.url));
      });
      return {
        ...state,
        activePage: action.payload.url,
      };
    }
    case ActionPageTypes.LOAD_PAGE_CONFIG: {
      return {
        ...state,
        isLoad: false,
      };
    }
    case ActionPageTypes.LOAD_PAGE_CONFIG_SUCCESS: {
      return {
        ...state,
        config: action.payload,
        isLoad: true,
      };
    }
    case ActionPageTypes.LOAD_FILTER_SUCCESS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: action.payload.data,
        },
      };
    }
    case ActionPageTypes.LOAD_MENU: {
      return {
        ...state,
        loadData: false,
      };
    }
    case ActionPageTypes.LOAD_MENU_SUCCESS: {
      return {
        ...state,
        menu: action.payload,
        loadData: true,
      };
    }

    case ActionPageTypes.CREATE_PROFILE_SUCCESS: {
      const client = [...state.filters.client];

      if (client.length > 0) {
        client.push(action.payload.client);
        client.sort();
      }

      return {
        ...state,
        defaultClient: action.payload.client,
        filters: {
          ...state.filters,
          client,
        }
      };
    }
    default: return state;
  }
};

export {
  reducer,
  initialState,
};