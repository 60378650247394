import { combineReducers } from 'redux';
import * as Ui from './ui';
import * as Config from './config';
import * as Page from './page';

export interface State {
  ui: Ui.State;
  config: Config.State;
  page: Page.State;
}

const initialState: State = {
  ui: Ui.initialState,
  config: Config.initialState,
  page: Page.initialState,
};

const reducer = combineReducers<State>({
  ui: Ui.reducer,
  config: Config.reducer,
  page: Page.reducer,
});

export {
  initialState,
  reducer,
  Ui,
  Config,
};
