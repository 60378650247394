import { Http } from './';
import { PageConfig } from '../types/page';

const config = (url: string) => Http.get<PageConfig>('node/' + url);

const filter = (url: string, parameter: string) => {
  return Http.get('node/' + url + (parameter ? '/' + parameter : ''));
};

const menu = (client: string, menuType: string) => Http.get(`node/leap/full-menu?client=${client}&menuType=${menuType}`);

const editMenu = (menuId: number, data: { [x: string]: any }) => Http.post(`node/leap/full-menu/${menuId}`, data);
const editGroup = (groupId: number, data: { [x: string]: any }) => Http.post(`node/leap/full-menu/group/${groupId}`, data);

const createProfile = (data: any) => Http.post<{ client: string }>('node/leap/full-menu', data);

const deleteMenu = (menuId: number) => Http.remove(`node/leap/full-menu/${menuId}`);
const deleteMenuGroup = (groupId: number) => Http.remove(`node/leap/full-menu/group/${groupId}`);

const createMenu = (data: any) => Http.post('node/leap/full-menu/create', data);
const createMenuGroup = (data: any) => Http.post('node/leap/full-menu/group/create', data);

export {
  config,
  filter,
  menu,
  editMenu,
  editGroup,
  createProfile,
  deleteMenu,
  deleteMenuGroup,
  createMenu,
  createMenuGroup,
};
