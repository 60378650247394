import { call, put } from 'redux-saga/effects';
import { Admin } from '../api';

import { SetActivePage } from '../actions/page';


function* logOut() {
  try {
    yield call(Admin.logOut);

    yield put(SetActivePage({ name: 'login', url: '/main/auth' }));
    location.reload();
  } catch (e) {
    console.error(e);
  }
}

export {
  logOut,
};