import * as React from 'react';
import { findDOMNode } from 'react-dom';
import { shallowEqualArrays } from '@vigo/util';

interface Props {
  list: string[];
  parentKey?: string;
  initValue?: string;
  dataUpdate: (value: string) => void;
}

interface State {
  value: string;
  open: boolean;
}


class Component extends React.PureComponent<Props, State> {

  state = {
    value: typeof this.props.initValue === 'string' ? this.props.initValue : this.props.list.length > 0 ? this.props.list[0] : '',
    open: false,
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (event: any) => {
    const domNode = findDOMNode(this);
    if (typeof domNode === 'string') {
      return;
    }
    if ((!domNode || !domNode.contains(event.target))) {
      this.setState({ ...this.state, open: false });
    }
  }

  componentWillReceiveProps(props: Props) {
    if (shallowEqualArrays(this.props.list, props.list) && this.props.parentKey === props.parentKey && this.props.initValue === props.initValue) {
      return;
    }
    const value = typeof props.initValue === 'string' ? props.initValue : props.list.length > 0 ? props.list[0] : '';
    this.setState({
      ...this.state,
      value,
    });
    this.props.dataUpdate(value);
  }

  render() {
    return (
      <div className={this.state.open ? "input-block select-block active" : "input-block select-block"} >
        <input readOnly={true} className="input-form" name="client" type="text" value={this.state.value} onClick={() => {
          this.setState({ ...this.state, open: !this.state.open });
        }} />
        <ul className="select-list">
          {this.props.list.map((item) => (
            <li key={item} className="item" onClick={(e) => {
              this.setState({
                value: item,
                open: false,
              });
              this.props.dataUpdate(item);
              e.stopPropagation();
            }}>{item}</li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Component;
