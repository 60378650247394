import React, { useEffect, useState, useMemo, useCallback, memo } from 'react';
import { MultiSelect, toOption } from '../common/ui/select';
import styled from 'styled-components';


interface IProps {
    title: string;
    close: () => void;
    create: (...props: any[]) => void;
    selectList: IRegion[];
}

export interface IRegion {
    regionId: number;
    regionName: string;
}

const Wrapper = styled.div`
`;

const ModalBody = styled.div`
    display: block;
    width: 100%;
    margin-top: 30px;
    padding: 0px 30px;
    box-sizing: border-box;
`;

const MultipleSelect = memo(({ title, close, create, selectList }: IProps) => {
    const [regionSelectionList, setRegionSelectionList] = useState<Array<IRegion>>([]);
    const [regionCopyList, setRegionCopyList] = useState<Array<IRegion>>([]);

    const multiselectOnChange = useCallback((idList: number[]) => {
        if (!idList) {
            return;
        }
        const list = regionSelectionList.filter(x => idList.indexOf(x.regionId) !== -1);
        setRegionCopyList(list);
    }, [regionSelectionList]);

    useEffect(() => {
        if (!selectList) {
            return;
        }
        if (regionSelectionList.length > 0) {
            return;
        }
        if (regionCopyList.length > 0) {
            return;
        }
        setRegionSelectionList(selectList);
    }, [regionSelectionList, regionCopyList, selectList]);

    const regionCopyListOptions = useMemo(() => regionCopyList.map(x => toOption({ id: x.regionId, titleKey: x.regionName })), [regionCopyList])
    const regionSeletionListOptions = useMemo(() => regionSelectionList.map(x => toOption({ id: x.regionId, titleKey: x.regionName })), [regionSelectionList]);
    return (
        <Wrapper>
            <section className="page-modal active">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">{title}</h3>
                        <a className="modal-close" onClick={close} />
                    </div>
                    <ModalBody className="modal-body">
                    <label className="input-label">{'Выберите регионы'}</label>
                    <MultiSelect
                        value={regionCopyListOptions}
                        options={regionSeletionListOptions}
                        onChange={multiselectOnChange}
                    />
                    </ModalBody>
                    <input className="btn modal-btn" value={'Создать ' + regionCopyList.length + ' шт.'} onClick={() => create(regionCopyList)} readOnly />
                </div>
            </section>
        </Wrapper>

    );

});
export default MultipleSelect;
