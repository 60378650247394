import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import ReactTable from 'react-table';

import { State } from '../../../reducers';

import Confirmation from '../../confirmation';
import Notification from '../../notification';

import { Wrapper, Delete } from './style';

interface IProps {

}

interface IStats {
  client: string;
  geo: number;
  cell_wave: number;
  planning_wave: number;
  wave: number;
}

export const Stats = ({ }: IProps) => {
  const activePage = useSelector((state: State) => state.page.activePage);
  const [stats, setStats] = useState<IStats[]>([]);
  const [loading, setLoading] = useState(false);
  const [removeClient, setRemoveClient] = useState<string | null>(null);
  const [notification, setNotification] = useState<"yes" | "no" | null>(null);

  const loadStats = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get('/node/wave/inventory/stats');
      setLoading(false);
      if (!Array.isArray(response.data)) {
        return;
      }
      setStats(response.data);

    } catch (e) {
      console.error(e);
    }
  }, [setLoading, setStats]);

  useEffect(() => {
    if (activePage !== '/wave/inventory') {
      return;
    }
    loadStats()
  }, [activePage, loadStats]);

  const remove = useCallback(async () => {
    try {
      setRemoveClient(null);

      const response = await axios.get(`/node/wave/inventory/remove/${removeClient}`);
      if(response.data.status === 'ok'){
        loadStats();
        setNotification('yes');
      } else {
        setNotification('no');
      }

    } catch (e) {
      console.error(e);
      setNotification('no');
    }
  }, [removeClient]);

  return (
    <Wrapper>
      {notification? <Notification status={notification} close={()=> setNotification(null)}/> : null}
      {removeClient ? <Confirmation
        text={`Удалить Inventory для клиента ${removeClient}`}
        close={() => setRemoveClient(null)}
        ok={remove}
      /> : null}
      <ReactTable
        data={stats}
        loading={loading}
        loadingText={'Загрузка...'}
        columns={[
          {
            Header: 'Клиент',
            accessor: 'client',
          },
          {
            Header: 'Geo',
            accessor: 'geo',
          },
          {
            Header: 'Wave',
            accessor: 'wave',
          },
          {
            Header: 'Cell wave',
            accessor: 'cell_wave',
          },
          {
            Header: 'Planning wave',
            accessor: 'planning_wave',
          },
          {
            id: 'remove',
            Header: '',
            width: 100,
            sortable: false,
            accessor: ({ client }) => <Delete title="Удалить" onClick={() => setRemoveClient(client)} />
          }
        ]}
        pageSize={10}
      />
    </Wrapper>
  );
};
