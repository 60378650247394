import React, { memo, useCallback } from "react";
import ReactSelect from 'react-select';

import { selectStyle } from './style';
import { IOption } from './types';


interface IProps {
  value: IOption;
  options: IOption[];
  onChange: (id: number) => void;
}

export const Select = memo(({ value, options, onChange }: IProps) => {
  const change = useCallback((option: IOption) => {
    onChange(option.value);
  }, [onChange]);


  return (
    <ReactSelect
      isMulti={false}
      value={value}
      options={options}
      onChange={change}
      hideSelectedOptions={false}
      closeMenuOnSelect={true}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#b0b0b0',
        },
      })}
      styles={selectStyle}
    />
  );
});
