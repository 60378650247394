import { Action, ActionUiTypes } from '../actions/ui';

export interface State {
  activeHeader: boolean;
}

const initialState: State = {
  activeHeader: false,
};

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionUiTypes.ACTIVE_HEADER: {
      return {
        ...state,
        activeHeader: action.payload,
      };
    }
    default: return state;
  }
};

export {
  initialState,
  reducer,
};