import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from '../store';
import { Page } from '../containers';
import Table from '../components/page/table';
import { SelectableTable } from '../components/page/selectable-table';
import { InventoryPage } from '../components/inventory-page';
import { TransportConfPage } from '../components/transport-conf-page';

type FuncName = 'getOptions' | 'openPage';

const funcs = (window as any)._funcs;


class Bridge {
  static getFunc(funcName: 'openPage'): Promise<(href: string, title: string, id: string) => void>;
  static getFunc(funcName: 'getOptions'): Promise<() => any>;
  static getFunc(funcName: FuncName): Promise<() => any> {
    const func = getFunc(funcName);
    return func;
  }

  static initReactPage(wrapper: HTMLElement, options: any) {
    console.log('initReactPage');
    console.log(wrapper);
    console.log(options);
    console.log('initReactPage!');
    if (options.idField === "menuId") {
      ReactDOM.render(
        <Provider store={store}>
          <Page filters={{}} page={options} loadData={false} />
        </Provider>,
        wrapper
      );
    } else if (options.customPage === 'react-selectable-table') {
      ReactDOM.render(
        <SelectableTable options={options} />,
        wrapper
      );
    } else if (options.customPage === 'react-inventory') {
      ReactDOM.render(
        <Provider store={store}>
          <InventoryPage />
        </Provider>,
        wrapper
      );
    } else if (options.customPage === 'react-transport') {
      ReactDOM.render(
        <Provider store={store}>
          <TransportConfPage />
        </Provider>,
        wrapper
      );
    } else {
      ReactDOM.render(
        <Table page={options} />,
        wrapper
      );
    }
  }
}

const getFunc = async (funcName: FuncName) => {
  while (true) {
    if (funcs[funcName]) {
      return funcs[funcName];
    }
    await new Promise((resolve) => setTimeout(() => resolve(), 100));
  }
};


(window as any).bridge = Bridge;

export default Bridge;
