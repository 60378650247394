import { Page, PageConfig } from '../types/page';
import { Menu } from '../types';

import { action } from 'typesafe-actions';

export enum ActionPageTypes {
  SET_ACTIVE_PAGE = '[page] SET_ACTIVE_PAGE',

  LOAD_PAGE_CONFIG = '[page] LOAD_PAGE_CONFIG',
  LOAD_PAGE_CONFIG_SUCCESS = '[page] LOAD_PAGE_CONFIG_SUCCESS',

  LOAD_FILTER = '[page] LOAD_FILTER',
  LOAD_FILTER_SUCCESS = '[page] LOAD_FILTER_SUCCESS',

  LOAD_MENU = '[page] LOAD_MENU',
  LOAD_MENU_SUCCESS = '[page] LOAD_MENU_SUCCESS',

  EDIT_MENU = '[page] EDIT_MENU',

  EDIT_GROUP = '[page] EDIT_GROUP',

  CREATE_PROFILE = '[page] CREATE_PROFILE',
  CREATE_PROFILE_SUCCESS = '[page] CREATE_PROFILE_SUCCESS',

  DELETE_MENU = '[page] DELETE_MENU',

  UPDATE_MENU = '[page] UPDATE_MENU',

  CREATE_MENU = '[page] CREATE_MENU',

  createMenuGroup = '[page] createMenuGroup',
  deleteMenuGroup = '[page] deleteMenuGroup',
}


export interface SetActivePageAction {
  type: ActionPageTypes.SET_ACTIVE_PAGE;
  payload: Page;
}

export interface LoadPageConfigAction {
  type: ActionPageTypes.LOAD_PAGE_CONFIG;
  payload: {
    url: string;
  };
}
export interface LoadPageConfigSuccessAction {
  type: ActionPageTypes.LOAD_PAGE_CONFIG_SUCCESS;
  payload: PageConfig;
}

export interface LoadFilterAction {
  type: ActionPageTypes.LOAD_FILTER;
  payload: {
    name: string;
    url: string;
    parameter?: string;
  };
}

export interface LoadFilterSuccessAction {
  type: ActionPageTypes.LOAD_FILTER_SUCCESS;
  payload: {
    data: string[];
    name: string;
  };
}

export interface LoadMenuAction {
  type: ActionPageTypes.LOAD_MENU;
  payload: {
    client: string;
    menuType: string;
  };
}

export interface LoadMenuSuccessAction {
  type: ActionPageTypes.LOAD_MENU_SUCCESS;
  payload: Menu[];
}

export interface EditMenuAction {
  type: ActionPageTypes.EDIT_MENU;
  payload: {
    menuId: number;
    menu: { [x: string]: any };
    client: string;
    menuType: string;
  };
}

export interface EditGroupAction {
  type: ActionPageTypes.EDIT_GROUP;
  payload: {
    groupId: number;
    menu: { [x: string]: any };
    client: string;
    menuType: string;
  };
}

export interface CreateProfileAction {
  type: ActionPageTypes.CREATE_PROFILE;
  payload: any;
}

export interface CreateProfileSuccessAction {
  type: ActionPageTypes.CREATE_PROFILE_SUCCESS;
  payload: {
    client: string;
  };
}

export interface DeleteMenuAction {
  type: ActionPageTypes.DELETE_MENU;
  payload: {
    menuId: number;
    client: string;
    menuType: string;
  };
}

export interface UpdateMenuAction {
  type: ActionPageTypes.UPDATE_MENU;
  payload: {
    client: string;
    menuType: string;
  };
}

export interface CreateMenuAction {
  type: ActionPageTypes.CREATE_MENU;
  payload: {
    menu: any;
    client: string;
    menuType: string;
  };
}

export const SetActivePage = (page: Page): SetActivePageAction => ({ type: ActionPageTypes.SET_ACTIVE_PAGE, payload: page });

export const LoadPageConfig = (url: string): LoadPageConfigAction => ({ type: ActionPageTypes.LOAD_PAGE_CONFIG, payload: { url } });
export const LoadPageConfigSuccess = (config: PageConfig): LoadPageConfigSuccessAction => ({ type: ActionPageTypes.LOAD_PAGE_CONFIG_SUCCESS, payload: config });

export const LoadFilter = (name: string, url: string, parameter?: string): LoadFilterAction => ({
  type: ActionPageTypes.LOAD_FILTER,
  payload: {
    name,
    url,
    parameter,
  }
});

export const LoadFilterSuccess = (name: string, result: string[]): LoadFilterSuccessAction => ({
  type: ActionPageTypes.LOAD_FILTER_SUCCESS,
  payload: {
    name,
    data: result,
  },
});

export const LoadMenu = (client: string, menuType: string): LoadMenuAction => ({
  type: ActionPageTypes.LOAD_MENU,
  payload: {
    client,
    menuType,
  },
});

export const LoadMenuSuccess = (menu: Menu[]): LoadMenuSuccessAction => ({ type: ActionPageTypes.LOAD_MENU_SUCCESS, payload: menu });

export const EditMenu = (client: string, menuType: string, menuId: number, menu: { [x: string]: any }): EditMenuAction => ({
  type: ActionPageTypes.EDIT_MENU,
  payload: {
    menuId,
    menu,
    client,
    menuType,
  },
});

export const EditGroup = (client: string, menuType: string, groupId: number, menu: { [x: string]: any }): EditGroupAction => ({
  type: ActionPageTypes.EDIT_GROUP,
  payload: {
    groupId,
    menu,
    client,
    menuType,
  },
});

export const CreateProfile = (data: any): CreateProfileAction => ({
  type: ActionPageTypes.CREATE_PROFILE,
  payload: data,
});

export const CreateProfileSuccess = (client: string): CreateProfileSuccessAction => ({
  type: ActionPageTypes.CREATE_PROFILE_SUCCESS,
  payload: {
    client,
  },
});

export const DeleteMenu = (client: string, menuType: string, menuId: number): DeleteMenuAction => ({
  type: ActionPageTypes.DELETE_MENU,
  payload: {
    menuId,
    client,
    menuType,
  }
});

export interface MenuGroup {
  groupId: number;
  menuId: number;
  title: string;
  enable: boolean;
  selected: boolean;
  groupLevel: number;
}

export const CreateMenuGroup = (menuGroup: MenuGroup, client: string, menuType: string) => action(ActionPageTypes.createMenuGroup, menuGroup, {
  client, menuType,
});

export type CreateMenuGroupAction = ReturnType<typeof CreateMenuGroup>;

export const DeleteMenuGroup = (client: string, menuType: string, menuId: number, groupId: number) => action(ActionPageTypes.deleteMenuGroup,
  ({ client, menuType, menuId, groupId }));

export type DeleteMenuGroupAction = ReturnType<typeof DeleteMenuGroup>;

export const UpdateMenu = (client: string, menuType: string): UpdateMenuAction => ({
  type: ActionPageTypes.UPDATE_MENU,
  payload: {
    client,
    menuType,
  }
});

export const CreateMenu = (menu: any, client: string, menuType: string): CreateMenuAction => ({
  type: ActionPageTypes.CREATE_MENU,
  payload: {
    menu,
    client,
    menuType,
  },
});

export type Action =
  SetActivePageAction
  | LoadPageConfigAction
  | LoadPageConfigSuccessAction
  | LoadFilterAction
  | LoadFilterSuccessAction
  | LoadMenuAction
  | LoadMenuSuccessAction
  | EditMenuAction
  | EditGroupAction
  | CreateProfileAction
  | CreateProfileSuccessAction
  | DeleteMenuAction
  | UpdateMenuAction
  | CreateMenuAction
  | DeleteMenuGroupAction
  | CreateMenuGroupAction
  ;
