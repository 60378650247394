import * as React from 'react';

const TableLoader = () => (
  <div className="table-wrapper">
    <div className="loading active">
      <span className="circle" />
      <span className="circle" />
      <span className="circle" />
      <span className="circle" />
      <span className="circle" />
      <span className="circle" />
      <span className="circle" />
    </div>
  </div>
);

export default TableLoader;