import React from 'react';
import styled from 'styled-components';

interface IProps {
  checked?: boolean;
  readOnly?: boolean;
  onChange?: () => void;
}

const Wrapper = styled.div`

  display: inline-block;
  width: 12px;
  height: 12px;
  label {
    font-family: arial;
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 16px;
  }

  label input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  div {
    position: absolute;
    top: 2px;
    left: 0;
    height: 12px;
    width: 12px;
    border: 2px solid #000000;
    border-radius: 2px;
  }

  label input:checked~div {
    background: #000000;
  }

  label input:disabled~div {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }

  div:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
  }

  label input:checked~div:after {
    display: block;
  }

  label div:after {
    left: 2px;
    top: 0px;
    width: 2px;
    height: 5px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  label input:disabled~div:after {
    border-color: #7b7b7b;
  }

  label div::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4.5rem;
    height: 4.5rem;
    margin-left: -1.3rem;
    margin-top: -1.3rem;
    background: #2aa1c0;
    border-radius: 3rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
  }
`;

export const Checkbox = ({ checked, onChange, readOnly }: IProps) => {
  return <Wrapper>
    <label>
      <input type='checkbox' checked={checked} onChange={onChange} readOnly={readOnly} />
      <div />
    </label>
  </Wrapper>;
};
