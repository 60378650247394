import { State } from '../reducers';
import { createSelector } from 'reselect';

/*
 * Get the todos state from the root state
 */
const getUiState = ((state: State) => state.ui);

/*
 * Getting todos array from todos State
 */
const getUi = createSelector([getUiState], (s) => s.activeHeader);

export default getUi;
