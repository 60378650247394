import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Header, Menu } from './containers';
import store from './store';
import './actions';


ReactDOM.render(
  <Provider store={store}>
    <>
      <Header />
      <Menu />
    </>
  </Provider>,
  document.getElementById('root')
);
