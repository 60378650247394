import styled from 'styled-components';

const Create = styled.button`
display: inline-block;
width: 20px;
height: 20px;
margin: 0;
padding: 0;
background-color: transparent;
background-image: url(../img/add.png);
background-position: 50% 50%;
background-repeat: no-repeat;
background-size: 18px;
cursor: pointer;
:hover {
  filter: brightness(50%);
}
`;

const Edit = styled.button`
display: inline-block;
width: 20px;
height: 20px;
margin: 0;
margin-left: 10px;
padding: 0;
background-color: transparent;
background-image: url(../img/edit.png);
background-position: 50% 50%;
background-repeat: no-repeat;
background-size: 16px;
cursor: pointer;
:hover {
  filter: brightness(50%);
}
`;

const Delete = styled.button`
display: inline-block;
width: 20px;
height: 20px;
margin: 0;
margin-left: 10px;
padding: 0;
background-color: transparent;
background-image: url(../img/trash.png);
background-position: 50% 50%;
background-repeat: no-repeat;
background-size: 15px;
cursor: pointer;
:hover {
  filter: brightness(50%);
}
`;

export {
  Edit,
  Delete,
  Create,
};