import styled from 'styled-components';


const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 25px;
  box-sizing: border-box;
  background-color: #365d6d;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const Title = styled.h2`
  font-size: 28px;
  line-height: 32px;
  font-weight: normal;
  font-family: HelveticaNeue, Arial;
  letter-spacing: 0.9px;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 18px;
  }
`;

const Logo = styled.img`
  display: block;
  width: 110px;
  height: 36px;
  @media (max-width: 768px) {
    width: 91px;
    height: 30px;
  }
`;


export {
  Header,
  Title,
  Logo,
};
