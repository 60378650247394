export enum ActionAdminTypes {
  LOG_OUT = '[admin] LOG_OUT',
}

export interface LogOutAction {
  type: ActionAdminTypes.LOG_OUT;
}

export const LogOut = (): LogOutAction => ({ type: ActionAdminTypes.LOG_OUT });

export type Action = LogOutAction;
