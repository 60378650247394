import styled from 'styled-components';

export const Wrapper = styled.div``;

export const EditButton = styled.button`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0;
  margin-left: 10px;
  padding: 0;
  background-color: transparent;
  background-image: url(../img/edit.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 16px;
  cursor: pointer;
  :hover {
    filter: brightness(50%);
  }
`;

export const Copy = styled.button`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0;
  margin-left: 10px;
  padding: 0;
  background-color: transparent;
  background-image: url(../img/copy.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 16px;
  cursor: pointer;
  :hover {
    filter: brightness(50%);
  }
`;

export const Delete = styled.button`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0;
  margin-left: 10px;
  padding: 0;
  background-color: transparent;
  background-image: url(../img/trash.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 15px;
  cursor: pointer;
  :hover {
    filter: brightness(50%);
  }
`;

export const Item = styled.div<{ error?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 700px;
  border: ${(props) => props.error ? '1px solid red' : '1px solid white'};

  padding: 5px 2px;
  margin: 3px;

  .input-block{
    margin: 0;
  }
`;

export const Label = styled.label`
  margin: 16px;
  font-size: 16px;
`;

export const Button = styled.button<{disable: boolean}>`
  display: block;
  height: 30px;
  margin: 16px;
  padding: 3px 25px;
  border: none;
  box-sizing: border-box;
  font-family: FiraSans, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0.3px;
  text-align: center;
  color: #ffffff;
  cursor: ${(props) => props.disable ? 'default' : 'pointer'};
  background-color: #365d6d;

  &:hover {
    background-color: ${(props) => props.disable ? '#365d6d' : '#567ea2'};
  }
`;