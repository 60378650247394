import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Item = styled.div<{ error?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 700px;
  border: ${(props) => props.error ? '1px solid red' : '1px solid white'};

  padding: 5px 2px;
  margin: 3px;

  .input-block{
    margin: 0;
  }
`;

export const Label = styled.label`
  font-size: 16px;
`;

export const Button = styled.button<{disable: boolean}>`
  display: block;
  height: 30px;
  margin: 16px;
  padding: 3px 25px;
  border: none;
  box-sizing: border-box;
  font-family: FiraSans, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0.3px;
  text-align: center;
  color: #ffffff;
  cursor: ${(props) => props.disable ? 'default' : 'pointer'};
  background-color: #365d6d;

  &:hover {
    background-color: ${(props) => props.disable ? '#365d6d' : '#567ea2'};
  }
`;

export const CustomItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 700px;

  padding: 5px 2px;
  margin: 3px;

  .input-block{
    margin: 0;
  }
  .input-form{
    width: 295px;
  }
`;

export const CustomWrapper = styled.div``;

export const CustomSelect = styled.div`
  display: flex;
`;

export const CustomList = styled.div`
  margin-bottom: 20px;
`;

export const CustomListItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-top: 10px;
`;

