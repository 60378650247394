export enum ActionUiTypes {
  ACTIVE_HEADER = '[ui] ACTIVE_HEADER',
}

export interface ActiveHeaderAction {
  type: ActionUiTypes.ACTIVE_HEADER;
  payload: boolean;
}

export const ActiveHeader = (): ActiveHeaderAction => ({ type: ActionUiTypes.ACTIVE_HEADER, payload: true });
export const InActiveHeader = (): ActiveHeaderAction => ({ type: ActionUiTypes.ACTIVE_HEADER, payload: false });

export type Action = ActiveHeaderAction;
