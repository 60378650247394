import React, { useState } from 'react';
import axios from 'axios';

import Modal from '../../modal';
import { Wrapper, Button, } from './style';

interface IProps {
  serviceList: {serviceId: string, serviceName: string}[];
  regionList: {regionId: string, regionName: string}[];
  onImport: (status: UploadStatsEnum) => any;
  formatedStringToArray: (str: string) => Array<string>;
}

export enum UploadStatsEnum {
  SUCCESS,
  FAIL,
  UPLOADED,
}

export const Edit = (props: IProps) => {
  const [isCreate, setIsCreate] = useState(false);

  return (
    <Wrapper>
      <Button disable={false} onClick={() => setIsCreate(true)}>Создать</Button>
      {isCreate ? <Modal
        title={'Создать'}
        config={[
          {
            type: 'select',
            label: 'Сервис',
            name: 'service',
            selectList: ['', ...props.serviceList.map(x => x.serviceName)],
          },
          {
            type: 'select',
            label: 'Регион',
            name: 'region',
            selectList: ['', ...props.regionList.map((x) => x.regionName)],
          },
          {
            type: 'number',
            label: 'Тест',
            name: 'test',
          },
          {
            type: 'number',
            label: 'Timeout',
            name: 'timeout',
          },
          {
            type: 'number',
            label: 'Количество',
            name: 'amount',
          },
          {
            type: 'textarea',
            label: 'DNS IPs',
            name: 'referenceDnsIp',
          },
          {
            type: 'textarea',
            label: 'HOST TP',
            name: 'referenceHostTp',
          },
          {
            type: 'textarea',
            label: 'IP TP',
            name: 'referenceIpTp',
          },
        ]}
        close={() => {
          setIsCreate(false);
        }}
        save={async (newConfig: { [x: string]: string }) => {
          try {
            console.log(newConfig);
            let referenceDnsIpArray: Array<string> = [];
            if (newConfig.referenceDnsIp) {
              referenceDnsIpArray = props.formatedStringToArray(newConfig.referenceDnsIp);
            }
            let referenceHostTpArray: Array<string> = [];
            if (newConfig.referenceHostTp) {
              referenceHostTpArray = props.formatedStringToArray(newConfig.referenceHostTp);
            }
            let referenceIpTpArray: Array<string> = [];
            if (newConfig.referenceIpTp) {
              referenceIpTpArray = props.formatedStringToArray(newConfig.referenceIpTp);
            }

            const newConfWithNumbers: { [x: string]: number | string[] } = {};
            for (const [key, value] of Object.entries(newConfig)) {
              // console.log(key);
              if (key === 'service') {
                newConfWithNumbers[key] = parseInt(props.serviceList.find(x => x.serviceName === value).serviceId);
                continue;
              }
              if (key === 'region') {
                newConfWithNumbers[key] = parseInt(props.regionList.find(x => x.regionName === value).regionId);
                continue;
              }
                newConfWithNumbers[key] = parseInt(value);

            }
            newConfWithNumbers.referenceDnsIp = referenceDnsIpArray;
            newConfWithNumbers.referenceHostTp = referenceHostTpArray;
            newConfWithNumbers.referenceIpTp = referenceIpTpArray;

            const response = await axios.post(`/node/conf/transport/config/`, newConfWithNumbers);
            if (response.status === 201) {
              props.onImport(UploadStatsEnum.SUCCESS);
            } else {
              props.onImport(UploadStatsEnum.FAIL);
            }

          } catch (e) {
            console.error(e);
            props.onImport(UploadStatsEnum.FAIL);
          }
          setIsCreate(false);
        }}
      /> : null}
    </Wrapper>
  );
};
