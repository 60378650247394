import * as React from 'react';
import { DispatchProp } from 'react-redux';
import { Config } from '../reducers';
import { MenuSub } from './';

import { Nav, Ul, Li, Link, Exit } from './menu.style';
import { SetActivePage } from '../actions/page';

import exitImage from '../img/exit.png';
import { LogOut } from '../actions/admin';

interface Props extends DispatchProp {
  config: Config.State;
  open: boolean;
}

interface State {
  openSumMenu: string | null;
}

class Menu extends React.Component<Props, State> {

  state: State = {
    openSumMenu: null,
  };

  clickMenu(subMenu: string) {
    if (this.state.openSumMenu === subMenu) {
      this.setState({ ...this.state, openSumMenu: null });
    } else {
      this.setState({ ...this.state, openSumMenu: subMenu });
    }
  }

  render() {
    if (!this.props.config.isLoad || !this.props.open) {
      return null;
    }
    return (
      <Nav>
        {this.state.openSumMenu ? <MenuSub openPageEvent={(name: string, url: string) => {
          this.props.dispatch(SetActivePage({ name, url }));
          this.setState({ ...this.state, openSumMenu: null });
        }}
          closeEvent={() => this.setState({ ...this.state, openSumMenu: null })}
          structure={this.props.config.structure.find((x) => x.name === this.state.openSumMenu)} /> : null}

        <Ul>
          {this.props.config.structure.map((structure) => (
            <Li onClick={() => structure.blocks ? this.clickMenu(structure.name) : null} key={structure.name}>
              <Link
                disabled={!structure.blocks}
                selected={this.state.openSumMenu === structure.name}
                image={structure.style["background-image"]}
                positiony={structure.style["background-position-y"]}>{structure.title}</Link>
            </Li>
          ))}

          <Exit onClick={() => this.props.dispatch(LogOut())}>
            <Link
              disabled={false}
              selected={false}
              small={true}
              image={`url('${exitImage}')`}
              positiony="10px">Выход</Link>
          </Exit>

        </Ul>
      </Nav>
    );
  }
}

export default Menu;
