import { call, put } from 'redux-saga/effects';
import {
  LoadPageConfigAction,
  LoadPageConfigSuccess,
  LoadFilterAction,
  LoadFilterSuccess,
  LoadMenuAction,
  LoadMenuSuccess,
  EditMenuAction,
  LoadMenu,
  EditGroupAction,
  CreateProfileAction,
  CreateProfileSuccess,
  DeleteMenuAction,
  DeleteMenuGroupAction,
  UpdateMenu,
  CreateMenuAction,
  CreateMenuGroupAction,
} from '../actions/page';
import { Page } from '../api';


function* loadPageConfig(action: LoadPageConfigAction) {
  try {
    const config = yield call(Page.config, action.payload.url + '/config');
    yield put(LoadPageConfigSuccess(config));
  } catch (e) {
    console.error(e);
  }
}

function* loadFilter(action: LoadFilterAction) {
  try {
    const filters = yield call(Page.filter, action.payload.url, action.payload.parameter);
    console.log(filters);
    yield put(LoadFilterSuccess(action.payload.name, filters));
  } catch (e) {
    console.error(e);
  }
}

function* loadMenu(action: LoadMenuAction) {
  if (!action.payload.client || !action.payload.menuType) {
    return;
  }
  try {
    const menu = yield call(Page.menu, action.payload.client, action.payload.menuType);
    console.log(menu);
    yield put(LoadMenuSuccess(menu));
  } catch (e) {
    console.error(e);
  }
}

function* editMenu(action: EditMenuAction) {
  try {
    const result = yield call(Page.editMenu, action.payload.menuId, action.payload.menu);
    console.log(result);

    yield put(LoadMenu(action.payload.client, action.payload.menuType));
  } catch (e) {
    console.error(e);
  }
}


function* editGroup(action: EditGroupAction) {
  try {
    const result = yield call(Page.editGroup, action.payload.groupId, action.payload.menu);
    console.log(result);

    yield put(LoadMenu(action.payload.client, action.payload.menuType));
  } catch (e) {
    console.error(e);
  }
}

function* createProfile(action: CreateProfileAction) {
  try {
    const result = yield call(Page.createProfile, action.payload);
    yield put(CreateProfileSuccess(result.client));
  } catch (e) {
    console.error(e);
  }
}

function* deleteMenu(action: DeleteMenuAction) {
  try {
    const result = yield call(Page.deleteMenu, action.payload.menuId);
    console.log(result);
    yield put(UpdateMenu(action.payload.client, action.payload.menuType));
  } catch (e) {
    console.error(e);
  }
}

function* deleteMenuGroup(action: DeleteMenuGroupAction) {
  try {
    const result = yield call(Page.deleteMenuGroup, action.payload.groupId);
    console.log(result);
    yield put(UpdateMenu(action.payload.client, action.payload.menuType));
  } catch (e) {
    console.error(e);
  }
}


function* createMenu(action: CreateMenuAction) {
  try {
    const result = yield call(Page.createMenu, action.payload.menu);
    console.log(result);
    yield put(UpdateMenu(action.payload.client, action.payload.menuType));
  } catch (e) {
    console.error(e);
  }
}

function* createMenuGroup(action: CreateMenuGroupAction) {
  try {
    const result = yield call(Page.createMenuGroup, action.payload);
    console.log(result);
    yield put(UpdateMenu(action.meta.client, action.meta.menuType));
  } catch (e) {
    console.error(e);
  }
}

export {
  loadPageConfig,
  loadFilter,
  loadMenu,
  editMenu,
  editGroup,
  createProfile,
  deleteMenu,
  createMenu,
  deleteMenuGroup,
  createMenuGroup,
};