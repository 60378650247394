import { Config } from '../types';
export enum ActionConfigTypes {
  LOAD_CONFIG = '[config] LOAD_CONFIG',
  LOAD_CONFIG_SUCCESS = '[config] LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_ERROR = '[config] LOAD_CONFIG_ERROR',
}

export interface ConfigLoadAction {
  type: ActionConfigTypes.LOAD_CONFIG;
}

export interface ConfigLoadSuccessAction {
  type: ActionConfigTypes.LOAD_CONFIG_SUCCESS;
  payload: Config;
}

export interface ConfigLoadErrorAction {
  type: ActionConfigTypes.LOAD_CONFIG_ERROR;
}

export const LoadConfig = (): ConfigLoadAction => ({ type: ActionConfigTypes.LOAD_CONFIG });
export const LoadConfigSuccess = (config: Config = {}): ConfigLoadSuccessAction => ({ type: ActionConfigTypes.LOAD_CONFIG_SUCCESS, payload: config });
export const LoadConfigError = (): ConfigLoadErrorAction => ({ type: ActionConfigTypes.LOAD_CONFIG_ERROR });

export type Action = ConfigLoadAction | ConfigLoadSuccessAction | ConfigLoadErrorAction;
