import styled, { CSSProperties } from 'styled-components';


interface ISelectStyleOptions {
  data: {
    label: string;
    value: string;
    color?: string;
  };
  isDisabled?: boolean;
  isFocused?: boolean;
  isSelected?: boolean;
}

export const selectStyle: any = {
  option: (base: CSSProperties, { data, isSelected, isFocused }: ISelectStyleOptions) => ({
    ...base,
    color: 'var(--dark-color)',
    backgroundColor: 'transparent',
    ...(data.color ? {
      borderBottom: '1px solid #e0e0e0',
      margin: '0 10px',
      paddingLeft: '0',
      width: 'auto',
    } : {}),
  }),
  multiValue: (base: CSSProperties, { data }: ISelectStyleOptions) => ({
    ...base,
    minWidth: '50px',
    ...(data.color ? {
      backgroundColor: data.color,
    } : {}),
  }),
  multiValueRemove: () => ({
    display: 'none',
  }),
  container: (base: CSSProperties) => ({
    ...base,
    width: '100%',
    fontSize: 14,
  }),
  control: (base: CSSProperties, { isFocused, isDisabled }: ISelectStyleOptions) => ({
    ...base,
    width: '100%',
    height: 30,
    borderRadius: 0,
    border: `1px solid black`,
    backgroundColor: isDisabled ? '#979797' : base.backgroundColor,
  }),
  valueContainer: (base: CSSProperties) => ({
    ...base,
    height: 30,
    padding: '0 10px',
    width: 430,
    flexWrap: 'unset',
  }),
  input: (base: CSSProperties) => ({
    ...base,
    height: 30,
    lineHeight: '30px',
    padding: 0,
    margin: 0,
    borderColor: 'var(--grey-border)',
    borderRadius: '4px',
  }),
  indicatorsContainer: (base: CSSProperties, { isDisabled }: ISelectStyleOptions) => ({
    ...base,
    height: 30,
    display: isDisabled ? 'none' : base.display,
  }),
  indicatorContainer: () => ({
    display: 'none',
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (base: CSSProperties) => ({
    ...base,
    padding: 5,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: () => ({
    display: 'none',
  }),
  menu: (base: CSSProperties) => ({
    ...base,
    flexDirection: 'column',
    marginTop: '0',
    borderRadius: '0',
    zIndex: 100,
  }),
  menuList: (base: CSSProperties) => ({
    ...base,
    flexDirection: 'column',
    padding: '0',
  }),
  menuPortal: (base: CSSProperties) => ({
    ...base,
    zIndex: 110,
  }),
};

export const MultiSelectItem = styled.span<{color: string}>`
  background-color: ${(props) => props.color};
  padding: 2px 4px;
  margin: 0 0 0 12px;
`;
