import styled from 'styled-components';

const Wrapper = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: -251px;
  bottom: 0;
  width: 250px;
  background-color: #fff;
  border-right: 2px solid #d0d0d0;
  box-sizing: border-box;
  z-index: 100;

  @media (max-width: 768px) {
    right: -181px;
    width: 180px;
  }
`;

const Title = styled.h4`
  display: block;
  margin: 15px 0 10px;
  padding: 0 15px;
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  font-family: HelveticaNeue, Arial;
  letter-spacing: 0.7px;
  color: #656565;
  text-align: left;

  @media (max-width: 768px) {
    margin: 15px 0 5px;
    font-size: 14px;
    line-height: 16px;
  }
`;

const List = styled.ul`
  display: block;
  width: 100%;
  height: auto;
`;

const Link = styled.a<{ open: boolean; }>`
  display: block;
  width: 100%;
  height: auto;
  padding-top: 8px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 15px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 18px;
  font-weight: normal;
  font-family: HelveticaNeue, Arial;
  letter-spacing: 0.5px;
  color: #656565;
  text-align: left;

  ${(props) => props.open ? 'background-color: #c8e5ff;' : ''}

  :hover {
    background-color: #f1efef;
  }

  @media (max-width: 768px) {
    padding: 5px 10px 3px 20px;
  }
`;

export {
  Wrapper,
  List,
  Link,
  Title,
};