import { Column, TableCellRenderer } from 'react-table';
import { checkboxList, groupCheckboxList } from './checkbox';

const getColumns = (CellMenu: TableCellRenderer, CellGroup: TableCellRenderer) => {
  const columnButtonBlockMenu: Column = {
    Header: '',
    maxWidth: 90,
    Cell: CellMenu,
  };


  const subColumns: Column[] = [
    {
      Header: 'S',
      accessor: 'sort',
      maxWidth: 50,
    },
    {
      Header: 'ID',
      accessor: 'menuId',
      maxWidth: 50,
    },
    {
      Header: 'Заголовок',
      accessor: 'title' // String-based value accessors!
    },
    {
      Header: 'Функция подменю',
      accessor: 'submenuFunction'
    },
    {
      Header: 'Значение',
      accessor: 'value'
    },
    {
      Header: 'Параметр',
      accessor: 'parameter',
    },
    ...checkboxList,
    columnButtonBlockMenu,
  ];



  const columns: Column[] = [
    {
      Header: 'S',
      accessor: 'sort',
      maxWidth: 50,
    },
    {
      Header: 'ID',
      accessor: 'menuId',
      maxWidth: 50,
    },
    {
      Header: 'Заголовок',
      accessor: 'title' // String-based value accessors!
    },
    {
      Header: 'Параметр',
      accessor: 'parameter',
    },
    {
      Header: 'Функция подменю',
      accessor: 'submenuFunction'
    },
    ...checkboxList,

    columnButtonBlockMenu,
  ];





  const columnsGroup: Column[] = [
    {
      Header: 'S',
      accessor: 'sort',
      maxWidth: 50,
    },
    {
      Header: 'ID',
      accessor: 'groupId',
      maxWidth: 50,
    },
    {
      Header: 'Заголовок',
      accessor: 'title',
    },
    {
      Header: 'Уровень разделения',
      accessor: 'groupLevel',
    },
    ...groupCheckboxList,
    {
      Header: '',
      maxWidth: 70,
      Cell: CellGroup,
    },
  ];

  return {
    subColumns,
    columns,
    columnsGroup,
  };
};

export {
  getColumns,
};