import { State } from '../reducers';
import { createSelector } from 'reselect';

/*
 * Get the todos state from the root state
 */
const getPageState = ((state: State) => state.page);

/*
 * Getting todos array from todos State
 */
const getPage = createSelector([getPageState], (s) => s);

export default getPage;
