import { call, put } from 'redux-saga/effects';
import { LoadConfigSuccess, LoadConfigError } from '../actions/config';
import { GetConfig } from '../api';


function* loadConfig() {
  try {

    const config = yield call(GetConfig);

    yield put(LoadConfigSuccess(config));
    (window as any).load();
  } catch (e) {
    console.error(e);
    yield put(LoadConfigError());
  }
}

export {
  loadConfig,
};