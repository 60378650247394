import { connect } from 'react-redux';
import { Page } from '../components';
import { State } from '../reducers';
import { getPage } from '../selectors';

const mapStateToProps = (state: State) => ({
  pageConfig: getPage(state),
});

export default connect(mapStateToProps)(Page);
