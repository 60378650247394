import styled from 'styled-components';

export const Wrapper = styled.div`

`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  margin: 0;
  font-family: FiraSans, sans-serif;
  font-size: 35px;
  font-weight: normal;
  line-height: 45px;
  letter-spacing: 0.6px;
  text-align: left;
  color: #000000;
`;

export const TogleWrapper = styled.div`
  display: flex;
  margin-right: 2em;
`;

export const TogleButton = styled.button<{active: boolean}>`
  display: block;
  height: 30px;
  margin: 0;
  padding: 3px 25px;
  border: none;
  box-sizing: border-box;
  font-family: FiraSans, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0.3px;
  text-align: center;
  color: #ffffff;
  cursor: ${(props) => props.active ? 'default' : 'pointer'};
  background-color: ${(props) => props.active ? '#365d6d' : 'rgba(54, 93, 109, 0.7)'};

  &:hover {
    background-color: ${(props) => props.active ? '#365d6d' : '#567ea2'};
  }
`;
