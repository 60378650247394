import * as React from 'react';
import { createPortal } from 'react-dom';

interface Props {
    status: 'yes' | 'no';
    close: () => void;
}

class Notification extends React.PureComponent<Props, {}> {
    componentDidMount() {
        setTimeout(() => {
            const wrapper = document.querySelector(".notify");
            if (wrapper) {
                document.querySelector(".notify").classList.add("notify--nonactive");
            }
            this.props.close();
        }, 5000);
    }

    render() {
    return createPortal(
      <section>
        <div className="notify">
            <div className={`notify__status notify__status--${this.props.status}`}/>
            <div className='notify__text'>
                {this.props.status === 'yes' ? 'Sucess' : 'Failure'}
            </div>
        </div>
      </section>, document.getElementById('modals'));
  }
}

export default Notification;
