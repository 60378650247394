import * as React from 'react';
import logo from '../img/logo.png';

import { Header, Title, Logo } from './header.style';

interface Props {
  activeHeader: boolean;
}


class HeaderComponent extends React.Component<Props> {
  render() {
    if (this.props.activeHeader) {

      return (
        <Header>
          <Title>Панель администратора</Title>
          <Logo src={logo} alt="logo" />
        </Header>
      );
    } else {
      return null;
    }
  }
}

export default HeaderComponent;
