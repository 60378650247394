import React, { memo, useCallback } from "react";
import ReactSelect, { components } from 'react-select';

import { Checkbox } from '../checkbox';

import { selectStyle, MultiSelectItem } from './style';
import { IOption } from './types';


interface IProps {
  value: IOption[];
  options: IOption[];
  onChange?: (id: number[]) => void;
}

const Option = memo((props: any) => {
  return (
    components.Option && <>
          <components.Option {...props}>
            <Checkbox checked={props.isSelected} readOnly={true}/>
            <MultiSelectItem color={props.data.color}>{props.data.label}</MultiSelectItem>
          </components.Option>
        </>
  )
});

export const MultiSelect = memo(({ value, options, onChange }: IProps) => {
  const change = useCallback((options: readonly IOption[]) => {
    onChange ? onChange(options.map((x) => x.value)) : null;
  }, [onChange]);
  const isDisabled = !onChange;


  return (
    <ReactSelect
      isMulti={true}
      value={value}
      options={options}
      onChange={change}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isDisabled={isDisabled}
      components={{
        Option,
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#b0b0b0',
        },
      })}
      styles={selectStyle}
    />
  );
});
