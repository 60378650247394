import * as React from 'react';
import { findDOMNode } from 'react-dom';
import { Structure } from '../types/config';

import { Wrapper, Title, List, Link } from './menu.sub.style';

interface Props {
  structure: Structure;
  openPageEvent: (pageName: string, pageUrl: string) => void;
  closeEvent: () => void;
}

interface State {

}


class MenuSub extends React.Component<Props, State> {
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (event: any) => {
    const domNode = findDOMNode(this);
    if(typeof domNode === 'string'){
      return;
    }
    if ((!domNode || !domNode.contains(event.target))) {
      this.props.closeEvent();
    }
  }

  render() {
    return (
      <Wrapper>
        <Title>{this.props.structure.title}</Title>
        {this.props.structure.blocks.map((block) => (
          <div key={block.title}>
            <Title>{block.title}</Title>
            <List>
              {block.pages.map((page) => (
                <li key={page.name} >
                  <Link onClick={(e) => {
                    e.stopPropagation();
                    this.props.openPageEvent(page.name, `/${this.props.structure.name}/${page.name}`);
                  }} open={false} >{page.title}</Link>
                </li>
              ))}
            </List>
          </div>
        ))}
      </Wrapper>
    );
  }
}

export default MenuSub;
