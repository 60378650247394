import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import ReactTable from 'react-table';

import { State } from '../../../reducers';

import Confirmation from '../../confirmation';
import Notification from '../../notification';
import Modal from '../../modal';
import Select from '../../select';
import MultipleSelect from '../multiple-select';
import { Edit, UploadStatsEnum } from '../Edit';

import { Wrapper, EditButton, Delete, Copy, Item, Label } from './style';

import { ITransport } from '../transport-types';

interface IProps {

}

const arrayToFormatedString = (array: Array<string>): string => {
  let result = '';
  for (const s of array) {
    result += s + '\n';
  }
  return result
}

const formatedStringToArray = (str: string): Array<string> => {
  return str.split('\n').map(x => x.trim()).filter(x => x);
}

export const TransportConfigs = ({ }: IProps) => {
  const anyFilter = 'Любой';
  const activePage = useSelector((state: State) => state.page.activePage);
  const [transportConfigsToShow, setTransportConfigsToShow] = useState<ITransport[]>([]);
  const [transportConfigsAvailable, setTransportConfigsAvailable] = useState<ITransport[]>([]);

  const [loading, setLoading] = useState(false);
  const [editId, setEditId] = useState<{ id: number } | null>(null);
  const [copyId, setCopyId] = useState<{ id: number } | null>(null);
  const [removeId, setRemoveId] = useState<{ id: number } | null>(null);
  const [prevEditId, setPrevEditId] = useState<{ id: number } | null>(null);
  const [prevRegion, setPrevRegion] = useState<any | null>(null);
  const [notification, setNotification] = useState<"yes" | "no" | null>(null);

  const [serviceFilter, setServiceFilter] = useState('');
  const [serviceFilterList, setServiceFilterList] = useState<Array<string>>([]);
  const [regionFilter, setRegionFilter] = useState('');
  const [regionFilterList, setRegionFilterList] = useState<Array<string>>([]);
  const [fullRegionList, setFullRegionList] = useState<any>([]);
  const [fullServiceList, setFullServiceList] = useState<any>([]);


  useEffect(() => {
    updateTableData();
  }, [removeId, copyId, editId])

  useEffect(()=> {
    if(editId){
      setPrevEditId(editId);
    }
    if (transportConfigsToShow && editId) {
      const currentConfig: any = transportConfigsToShow.find((x) => x.id === editId.id);
      setPrevRegion(currentConfig.region);
      currentConfig.region = currentConfig.region.regionName;
      currentConfig.referenceDnsIp = arrayToFormatedString(currentConfig.referenceDnsIp);
      currentConfig.referenceHostTp = arrayToFormatedString(currentConfig.referenceHostTp);
      currentConfig.referenceIpTp = arrayToFormatedString(currentConfig.referenceIpTp);
    }
  }, [editId, transportConfigsToShow]);

  useEffect(() => {
    if(transportConfigsAvailable) {
      const serviceList = [anyFilter, ...transportConfigsAvailable.map(x => '' + x.service)];
      const regionList = [anyFilter, ...transportConfigsAvailable.map(x => '' + x.region.regionName)];

      setServiceFilterList([...new Set(serviceList)]);
      setRegionFilterList([...new Set(regionList)]);
    }
  }, [transportConfigsAvailable]);

  useEffect(() => {
    if (transportConfigsAvailable) {
      if (serviceFilter !== anyFilter && regionFilter !== anyFilter) {
        setTransportConfigsToShow(transportConfigsAvailable
          .filter(x => x.service === serviceFilter
            && x.region.regionName === regionFilter));
        return;
      }
      if (serviceFilter !== anyFilter ) {
        setTransportConfigsToShow(transportConfigsAvailable.filter(x => x.service === serviceFilter));
        return;
      }
      if (serviceFilter !== anyFilter && regionFilter !== anyFilter) {
        setTransportConfigsToShow(transportConfigsAvailable.filter(x => x.service === serviceFilter && x.region.regionName === regionFilter));
        return;
      }
      if (regionFilter !== anyFilter ) {
        setTransportConfigsToShow(transportConfigsAvailable.filter(x => x.region.regionName === regionFilter));
        return;
      }
      if (serviceFilter !== anyFilter) {
        setTransportConfigsToShow(transportConfigsAvailable.filter(x => x.service === serviceFilter));
        return;
      }
      if (regionFilter !== anyFilter) {
        setTransportConfigsToShow(transportConfigsAvailable.filter(x => x.region.regionName === regionFilter));
        return;
      }
      loadConfs();
    }
  }, [serviceFilter, regionFilter]);

  const loadConfs = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get('/node/conf/transport/config');
      setLoading(false);
      if (!Array.isArray(response.data)) {
        return;
      }
      setTransportConfigsAvailable(response.data);
      setTransportConfigsToShow(response.data);
      // setServiceFilter('');

    } catch (e) {
      console.error(e);
    }
  }, [setLoading, setTransportConfigsAvailable, setTransportConfigsAvailable]);

  const loadRegions = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get('/node/conf/transport/region');
      setLoading(false);
      if (!Array.isArray(response.data)) {
        return;
      }
      setFullRegionList(response.data)
    } catch (e) {
      console.error(e);
    }
  }, [setLoading, setFullRegionList]);

  const loadServices = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get('/node/conf/transport/service');
      setLoading(false);
      if (!Array.isArray(response.data)) {
        return;
      }
      setFullServiceList(response.data)
    } catch (e) {
      console.error(e);
    }
  }, [setLoading]);

  useEffect(() => {
    if (activePage !== '/processing/transport') {
      return;
    }
    loadConfs();
    loadRegions();
    loadServices();
  }, [activePage]);

  const onImport = async (status: UploadStatsEnum) => {
    switch (status) {
      case UploadStatsEnum.UPLOADED:
        loadConfs();
        setNotification('yes');
        break;
      case UploadStatsEnum.SUCCESS:
        loadConfs();
        setNotification('yes');
        break;
      case UploadStatsEnum.FAIL:
        setNotification('no');
        break;
    }
  }

  const updateTableData = useCallback(async () => {
    setRegionFilter(anyFilter);
    setServiceFilter(anyFilter);
    await loadConfs();
  }, [setRegionFilter, setServiceFilter, loadConfs]);

  const remove = async () => {
    const currentConfig = transportConfigsToShow.find(x => x.id === removeId.id);

    const response = await axios.post(`/node/conf/transport/config/delete/${currentConfig.id}`);
    if (response.status === 201) {
      setNotification('yes');
    } else {
      setNotification('no');
    }
    setRemoveId(null);
  }

  return (
    <Wrapper>
      <Item>
        <Label>Сервис</Label>
        <Select
          list={serviceFilterList}
          initValue={anyFilter}
          dataUpdate={setServiceFilter}
        />
        <Label>Регион</Label>
        <Select
          list={regionFilterList}
          initValue={anyFilter}
          dataUpdate={setRegionFilter}
        />
        {/* <Button disable={false} onClick={() => clearFilters()}>Обновить таблицу</Button> */}
        <Edit onImport={onImport} formatedStringToArray={formatedStringToArray} regionList={fullRegionList} serviceList={fullServiceList}/>
      </Item>
      {notification ? <Notification status={notification} close={() => setNotification(null)} /> : null}
      {editId ? <Modal
        title={'Редактировать'}
        config={[
          {
            type: 'const',
            label: 'Сервис',
            name: 'service',
          },
          {
            type: 'const',
            label: 'Регион',
            name: 'region',
          },
          {
            type: 'number',
            label: 'Тест',
            name: 'test',
          },
          {
            type: 'number',
            label: 'Timeout',
            name: 'timeout',
          },
          {
            type: 'number',
            label: 'Количество',
            name: 'amount',
          },
          {
            type: 'textarea',
            label: 'DNS IP',
            name: 'referenceDnsIp',
          },
          {
            type: 'textarea',
            label: 'HOST TP',
            name: 'referenceHostTp',
          },
          {
            type: 'textarea',
            label: 'IP TP',
            name: 'referenceIpTp',
          },
        ]}
        data={transportConfigsToShow.find((x) => x.id === editId.id) as any}
        close={() => {
          const currentConfig: any = transportConfigsToShow.find((x) => x.id === editId.id);
          let referenceDnsIpArray: Array<string> = [];
          currentConfig.region = prevRegion;
          if (currentConfig.referenceDnsIp) {
            referenceDnsIpArray = formatedStringToArray(currentConfig.referenceDnsIp);
          }
          let referenceHostTpArray: Array<string> = [];
          if (currentConfig.referenceHostTp) {
            referenceHostTpArray = formatedStringToArray(currentConfig.referenceHostTp);
          }
          let referenceIpTpArray: Array<string> = [];
          if (currentConfig.referenceIpTp) {
            referenceIpTpArray = formatedStringToArray(currentConfig.referenceIpTp);
          }
          currentConfig.referenceDnsIp = referenceDnsIpArray;
          currentConfig.referenceHostTp = referenceHostTpArray;
          currentConfig.referenceIpTp = referenceIpTpArray;
          setEditId(null);
        }}
        save={async (newConfig: { [x: string]: string }) => {
          try {
            const currentConfig: any = transportConfigsToShow.find((x) => x.id === editId.id);
            const referenceDnsIpArray = formatedStringToArray(newConfig.referenceDnsIp || currentConfig.referenceDnsIp);
            const referenceHostTpArray = formatedStringToArray(newConfig.referenceHostTp || currentConfig.referenceHostTp);
            const referenceIpTpArray = formatedStringToArray(newConfig.referenceIpTp || currentConfig.referenceIpTp);

            const newConfWithNumbers: { [x: string]: number | string[] } = {};
            for (const [key, value] of Object.entries(newConfig)) {
              newConfWithNumbers[key] = parseInt(value);
            }
            newConfWithNumbers.referenceDnsIp = referenceDnsIpArray;
            newConfWithNumbers.referenceHostTp = referenceHostTpArray;
            newConfWithNumbers.referenceIpTp = referenceIpTpArray;

            const response = await axios.put(`/node/conf/transport/config/${prevEditId.id}`, newConfWithNumbers);

            if (response.status === 201) {
              loadConfs();
              setNotification('yes');
            } else {
              onImport(UploadStatsEnum.FAIL);
            }

          } catch (e) {
            console.error(e);
            onImport(UploadStatsEnum.FAIL);
          }
          setEditId(null)
        }}
      /> : null}
      {copyId ?
        <MultipleSelect
          title='Копировать по регионам'
          close={() => {
            setCopyId(null);
          }}
          create={async (regionList) => {
            const currentConfig = transportConfigsToShow.find(x => x.id === copyId.id);
            const response = await axios.post(`/node/conf/transport/config/copy/${currentConfig.id}`, { currentConfig, regionList });
            if (response.status === 201) {
              setNotification('yes');
            } else {
              setNotification('no');
            }
            setCopyId(null);
          }}
          selectList={fullRegionList} /> : null}
      {removeId ? <Confirmation
        text={`Удалить выбранный config`}
        close={() => setRemoveId(null)}
        ok={remove}
      /> : null}
      <ReactTable
        data={transportConfigsToShow}
        loading={loading}
        loadingText={'Загрузка...'}
        columns={[
          {
            Header: 'Сервис',
            accessor: 'service',
          },
          {
            Header: 'Регион',
            accessor: 'region.regionName',
          },
          {
            Header: 'Тест',
            accessor: 'test',
          },
          {
            Header: 'Timeout',
            accessor: 'timeout',
          },
          {
            Header: 'Количество',
            accessor: 'amount',
          },
          {
            Header: 'DNS IP',
            accessor: 'referenceDnsIp',
          },
          {
            Header: 'HOST TP',
            accessor: 'referenceHostTp',
          },
          {
            Header: 'IP TP',
            accessor: 'referenceIpTp',
          },
          {
            id: 'edit',
            Header: '',
            width: 100,
            sortable: false,
            accessor: ({ id }) => <EditButton title="Редактировать" onClick={() => setEditId({ id })} />
          },
          {
            id: 'copy',
            Header: '',
            width: 100,
            sortable: false,
            accessor: ({ id }) => <Copy title="Копировать по регионам" onClick={() => setCopyId({ id })} />
          },
          {
            id: 'remove',
            Header: '',
            width: 100,
            sortable: false,
            accessor: ({ id }) => <Delete title="Удалить" onClick={() => setRemoveId({ id })} />
          },
        ]}
        pageSize={20}
      />
    </Wrapper>
  );
};
