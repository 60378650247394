import { Action, ActionConfigTypes } from '../actions/config';
import { Config } from '../types';

export interface State extends Config {
  isLoad: boolean;
}

const initialState: State = {
  isLoad: false,
};

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionConfigTypes.LOAD_CONFIG_SUCCESS: {
      return {
        ...state,
        auth: action.payload.auth,
        structure: action.payload.structure,
        isLoad: true,
      };
    }
    case ActionConfigTypes.LOAD_CONFIG_ERROR: {
      return {
        ...state,
        isLoad: false,
      };
    }
    default: return state;
  }
};

export {
  initialState,
  reducer,
};