import logger from 'redux-logger';
import { createStore, applyMiddleware, Action } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { State, reducer } from '../reducers';
import { ActionTypes } from '../actions';
import Saga from '../sagas';

const sagaMiddleware = createSagaMiddleware();

const store = createStore<State, Action<ActionTypes>, {}, {}>(reducer, applyMiddleware(logger, sagaMiddleware));

sagaMiddleware.run(Saga);

export default store;
