import React, { useState } from 'react';

import { Import } from './import';
import { Stats } from './stats';
import { Band } from './band';

import { Wrapper, TitleWrapper, Title, TogleWrapper, TogleButton } from './style';

interface IProps {

}

type ITab = 'import' | 'stats' | 'band';

export const InventoryPage = ({ }: IProps) => {
  const [activeTab, setActiveTab] = useState<ITab>('import');


  return (
    <Wrapper>
      <TitleWrapper>
        <Title>
        { activeTab === 'import' && 'Загрузка '}
        { activeTab === 'stats' && 'Статистика '}
        { activeTab === 'stats' && 'Настройка Band '}
          Inventory
      </Title>
        <TogleWrapper>
          <TogleButton onClick={() => setActiveTab('import')} active={activeTab === 'import'}>Загрузка</TogleButton>
          <TogleButton onClick={() => setActiveTab('stats')} active={activeTab === 'stats'}>Статистика</TogleButton>
          <TogleButton onClick={() => setActiveTab('band')} active={activeTab === 'band'}>Band</TogleButton>
        </TogleWrapper>
      </TitleWrapper>
      { activeTab === 'import' && <Import/>}
      { activeTab === 'stats' && <Stats/>}
      { activeTab === 'band' && <Band/>}
    </Wrapper>
  );
};
