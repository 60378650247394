import store from '../store';
import { ActiveHeader, InActiveHeader, ActionUiTypes } from './ui';
import { LoadConfig, ActionConfigTypes, LoadConfigError, LoadConfigSuccess } from './config';
import { ActionPageTypes } from './page';

(window as any).funcs = {
  ActiveHeader: () => {
    store.dispatch(ActiveHeader());
    store.dispatch(LoadConfigSuccess(store.getState().config));
  },
  InActiveHeader: () => {
    store.dispatch(InActiveHeader());
    store.dispatch(LoadConfigError());
  }
};

store.dispatch(LoadConfig());

export type ActionTypes = ActionUiTypes | ActionConfigTypes | ActionPageTypes;

