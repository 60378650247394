import * as React from 'react';
import axios from 'axios';
import "react-table/react-table.css";

import PageLoader from '../page.loader';

import { Column, TableCellRenderer } from 'react-table';

import { Edit, Delete, Create } from '../buttons';
import { Modal, Confirmation } from '..';
import Notification from '../notification';
import { Props, State } from '../table/types';
import { PageReactTable } from '../page/style';

class Component extends React.PureComponent<Props, State> {
    state: State = {
        data: [],
        loadData: true,
        openModal: null,
        modalData: {},
        openNotify: null,
        openConfirm: null,
        openConfirmText: null,
        expanded: {}
    };

    componentDidMount() {
        axios.get(this.props.page.queries.list.url)
            .then(res => {
                const data = this.buildTree(null, null, res.data, []);

                if(!data){
                  return;
                }
                
              
                this.setState({
                    data: this.buildTree(null, null, res.data, []),
                    loadData: false
                });
            }).catch(error => {
                console.error(error);
                this.setState({
                    openNotify: 'no'
                });
            });
    }

    /**
     * Выполняет операцию по удалению строки
     * @param row 
     */
    deleteElement(row: any) {
        const data = [...this.state.data];
        const depth = this.findElementDepth(row.original[this.props.page.idField], data, []);
        let path: any = null;
        depth.pop();

        if (depth.length === 1) {
            data.splice(depth[0], 1);
        } else {
            path = this.findElementByDepth(data[depth[0]], depth, depth.length - 1);
        }

        axios.delete(this.props.page.queries.delete.url, {
            params: {
                id: row.original[this.props.page.idField]
            }
        }).then(() => {
            if (path) {
                path.submenu.splice(depth[depth.length - 1], 1);
            }

            this.setState({
                data,
                openNotify: 'yes'
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                openNotify: 'no'
            });
        });
    }

    createElement(row: any) {
        const data = [...this.state.data];
        let path: any = null;

        if (this.state.openModal === 'createFirstNesting') {
            data.push(row);
        } else if (this.state.openModal === 'createUnknownNesting') {
            const depth = this.findElementDepth(row.parent, data, []);
            depth.pop();

            path = this.findElementByDepth(data[depth[0]], depth, depth.length);
        }

        axios.post(this.props.page.queries.create.url, {
            result: row
        }).then(() => {
            if (path) {
                if (!path.submenu) {
                    path.submenu = [];
                }

                path.submenu.push(row);
            }

            this.setState({
                data,
                openNotify: 'yes'
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                openNotify: 'no'
            });
        });
    }

    editElement(row: any, changedFields: any) {
        const data = [...this.state.data];
        const depth = this.findElementDepth(row[this.props.page.idField], data, []);
        depth.pop();
        const path = this.findElementByDepth(data[depth[0]], depth, depth.length);

        axios.post(this.props.page.queries.update.url, {
            result: row
        }).then(() => {
            for (const key in changedFields) {
                path[key] = changedFields[key];
            }

            this.setState({
                data,
                openNotify: 'yes'
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                openNotify: 'no'
            });
        });
    }

    /**
     * 
     * @param path 
     * @param depth 
     * @param bound 
     * @returns
     */
    findElementByDepth(path: any, depth: number[], bound: number) {
        depth.forEach((element, i) => {
            if (i !== 0 && i !== bound) {
                path = path.submenu[element];
            }
        });

        return path;
    }

    /**
     * 
     * @param idField 
     * @param array 
     * @param depth 
     * @returns
     */
    findElementDepth(idField: number, array: any[], depth: any[]) {
        if (array) {
            const index = array.findIndex(x => x[this.props.page.idField] === idField);

            if (index !== -1) {
                depth.push(index);
                depth.push(-1);
            } else {
                array.forEach((element, i) => {
                    if (depth[depth.length - 1] !== -1) {
                        depth.push(i);
                        this.findElementDepth(idField, element.submenu, depth);
                    }
                });
            }
        }

        if (depth[depth.length - 1] !== -1) {
            depth.pop();
        }

        return depth;
    }

    /**
     * Собирает данные в пригодный для отображения вид (с submenu)
     * @param array
     * @param id
     * @param data
     * @param depth
     * @returns
     */
    buildTree(array: any[], id: number, data: any[], depth: number[]) {
        const searchedData = data.filter(x => x.parent === id);

        if (searchedData.length !== 0) {
            if (!array) {
                array = searchedData;
            } else {
                this.findElementByDepth(array[depth[0]], depth, depth.length).submenu = searchedData;
            }

            searchedData.forEach((element, i) => {
                depth.push(i);

                this.buildTree(array, element[this.props.page.idField], data, depth);
            });
        }

        depth.pop();
        return array;
    }

    /**
     * Парсит названия колонок из файла options.json (this.props.page)
     * @param CellMenu 
     * @returns
     */
    getColumns(CellMenu: TableCellRenderer) {
        const columns: Column[] = this.props.page.table.fields.filter((x: any) => !x.hidden).map((field: any) => {
            if (field.style && field.style['max-width']) {
                return {
                    Header: field.title,
                    accessor: field.name,
                    maxWidth: parseInt(field.style['max-width'], 10)
                };
            } else {
                return {
                    Header: field.title,
                    accessor: field.name
                };
            }
        });

        columns.push({
            Header: 'Действия',
            maxWidth: 90,
            Cell: CellMenu
        });

        return columns;
    }

    /**
     * Собирает информацию о полях модального окна (заголовки, типы) по информации из options.json (this.props.page)
     * @returns
     */
    getConfig() {
        const config = this.props.page.table.fields.map((field: any) => {
            const label = field.title;
            const name = field.name;
            let type = field.disabled ? 'const' : null;

            if (!type) {
                switch (field.type) {
                    case 'number':
                        type = 'input';
                        break;
                    case 'string':
                        type = 'input';
                        break;
                    case 'enum':
                        type = 'select';
                        break;
                    case 'multi-select':
                        type = 'select';
                        break;
                    case 'boolean':
                        type = 'boolean';
                        break;
                    default:
                        type = 'input';
                }
            }

            const selectList = (type === 'select' && field.values) ? field.values.slice() : null;
            const required = field.required ? field.required : false;

            return {
                name,
                label,
                type,
                selectList,
                required
            };
        });

        return config;
    }

    /**
     * Собирает недостающую и существующую информацию о строке
     * @param row
     * @param method
     * @returns
     */
    async buildRowData(row: any, method: string) {
        let result: any = {};

        if (method === 'createFirstNesting' || method === 'createUnknownNesting') {
            const values = await Promise.all(this.props.page.table.fields.map((field: any) => {
                if (field.smartTip && field.smartTip.mean) {
                    return Promise.resolve(field.smartTip.mean);
                } else if (field.smartTip && field.smartTip.method) {
                    return axios.get(this.props.page.queries[field.smartTip.method].url);
                } else {
                    return Promise.resolve(null);
                }
            }));

            values.forEach((value: any, index) => {
                if (value) {
                    result[this.props.page.table.fields[index].name] = value.data ? value.data[Object.keys(value.data)[0]] : value;
                }
            });

            result.parent = null;
        }

        if (method !== 'createFirstNesting') {
            const depth = this.findElementDepth(row.original[this.props.page.idField], this.state.data, []);
            depth.pop();

            if (method === 'edit') {
                result = row.original;

                result.parent = depth.length === 1 ? null : this.findElementByDepth(this.state.data[depth[0]],
                    depth, depth.length - 1)[this.props.page.idField];
            } else if (method === 'createUnknownNesting') {
                result.parent = this.findElementByDepth(this.state.data[depth[0]], depth, depth.length)[this.props.page.idField];
            }
        }

        return result;
    }

    treeTable = (props: { submenu: any, columns: any }) => (
        <div style={{ padding: '0 0 0 20px' }}>
            <PageReactTable
                data={props.submenu}
                columns={props.columns}
                defaultSorted={[{ id: this.props.page.idField, desc: true }]}
                defaultPageSize={props.submenu.length}
                showPagination={false}
                SubComponent={
                    props.submenu.some((sub: any) => {
                        return sub.submenu && sub.submenu.length > 0;
                    })
                        ? (row) => {
                            return row.original.submenu && row.original.submenu.length > 0 ?
                                (<this.treeTable submenu={row.original.submenu} columns={props.columns} />) : null;
                        }
                        : undefined
                }
            />
        </div>
    )

    render() {
        const confirmationText = `Вы уверены, что хотите удалить ${this.state.openConfirmText} запись?`;
        const columns = this.getColumns((row) => (
            <>
                <Create onClick={async () => {
                    this.setState({
                        openModal: 'createUnknownNesting',
                        modalData: await this.buildRowData(row, 'createUnknownNesting')
                    });
                }} />
                <Edit onClick={async () => {
                    this.setState({
                        openModal: 'edit',
                        modalData: await this.buildRowData(row, 'edit'),
                    });
                }} />
                <Delete onClick={() => {
                    console.log(row.original);
                    this.setState({
                        openConfirmText: row.original[this.props.page.idField],
                        openConfirm: () => {
                            this.deleteElement(row);
                        }
                    });
                }} />
            </>
        ));

        return (
            <>
                {this.state.openConfirm ?
                    <Confirmation
                        text={confirmationText}
                        ok={this.state.openConfirm}
                        close={() => this.setState({
                            openConfirm: null
                        })} />
                    : null}
                {this.state.openNotify ?
                    <Notification status={this.state.openNotify}
                        close={() => this.setState({
                            openNotify: null
                        })} />
                    : null}
                {this.state.openModal ?
                    <Modal
                        config={this.getConfig()}
                        data={this.state.modalData}
                        close={() => this.setState({
                            openModal: null,
                        })}
                        save={(data) => {
                            const result: any = Object.assign({}, this.state.modalData);

                            for (const key in data) {
                                result[key] = this.props.page.table.fields.find((x: any) => x.name === key).type === 'number' ? parseInt(data[key], 10) :
                                    data[key];
                            }

                            if (this.state.openModal === 'createFirstNesting' || this.state.openModal === 'createUnknownNesting') {
                                this.createElement(result);
                            } else if (this.state.openModal === 'edit') {
                                this.editElement(result, data);
                            }
                        }}
                        title={this.state.openModal === 'edit' ? 'Редактирование' : this.state.openModal === 'createUnknownNesting' ?
                            'Создание элемента неизвестной вложенности' : this.state.openModal === 'createFirstNesting' ?
                                'Создание элемента первой вложенности' : 'Создать'} />
                    : null}
                <div className='main-header'>
                    <h1 className='main-title'>{this.props.page.title}</h1>
                    <p className='main-description'>{this.props.page.description}</p>
                </div>
                <div className='main-content'>
                    <a className='btn content-btn' onClick={async () => {
                        this.setState({
                            openModal: 'createFirstNesting',
                            modalData: await this.buildRowData(null, 'createFirstNesting')
                        });
                    }}>Создать</a>
                    {this.state.loadData ?
                        <PageLoader /> :
                        <div className='table-wrapper'>
                            <PageReactTable
                                data={this.state.data}
                                columns={columns}
                                defaultSorted={[{ id: this.props.page.idField, desc: true }]}
                                expanded={this.state.expanded}
                                onExpandedChange={(expanded) => this.setState({ expanded })}
                                showPagination={true}
                                SubComponent={
                                    (this.state.data || []).some((sub: any) => {
                                        return sub.submenu && sub.submenu.length > 0;
                                    })
                                        ? (row) => {
                                            return (
                                                <div>
                                                    {row.original.submenu && row.original.submenu.length > 0 ?
                                                        <this.treeTable submenu={row.original.submenu} columns={columns} /> : null}
                                                </div>
                                            );
                                        }
                                        : undefined
                                }
                            />
                        </div>
                    }
                </div>
            </>
        );
    }
}

export default Component;