import { connect } from 'react-redux';
import { Menu } from '../components';
import { State } from '../reducers';
import { getConfig, getUi } from '../selectors';

const mapStateToProps = (state: State) => ({
  config: getConfig(state),
  open: getUi(state),
});

export default connect(mapStateToProps)(Menu);
